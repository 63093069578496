import {CHAT_LIST} from '@/store/types'
import network from '@/lib/network'

const state = () => ({
  chat_list: [],
  showAllChats: false
})
let showAllChats = false
const getters = {
  [CHAT_LIST.GETTERS.GET_CHAT_LIST]: (state) => {
    const sort = (chatsList) => {
      return chatsList
        .sort((a, b) => {
          return a.id >= b.id ? -1 : 1
        })
        .sort((a, b) => {
          const dateA = Date.parse(a.updated_at)
          const dateB = Date.parse(b.updated_at)

          if (dateA === dateB) {
            return 0
          }
          return dateA >= dateB ? -1 : 1
        })
    }

    const closed = state.chat_list.filter((chat) => chat.status === `closed`)
    const opened = state.chat_list.filter((chat) => chat.status !== `closed`)

    return [...sort(opened), ...sort(closed)]
  }
}

const mutations = {
  [CHAT_LIST.MUTATIONS.CHANGE_SHOW_ALL]: (state) => {
    state.showAllChats = !state.showAllChats
    showAllChats = !showAllChats
  },
  [CHAT_LIST.MUTATIONS.SET_CHAT_LIST]: (state, list) => {
    state.chat_list = list
  },
  [CHAT_LIST.MUTATIONS.ADD_CHAT]: (state, chat) => {
    state.chat_list.unshift(chat)
  },
  [CHAT_LIST.MUTATIONS.CLOSE_CHAT]: (state, chatId) => {
    const chat = state.chat_list.find((c) => c.pk === chatId)
    if (chat) {
      chat.status = `closed`
    }
  },
  [CHAT_LIST.MUTATIONS.INCREMENT_CHAT_LIST_COUNTERS]: (state, id) => {
    state.chat_list.forEach((e) => {
      if (e.pk === id) {
        e.unread_amount++
      }
    })
  },
  [CHAT_LIST.MUTATIONS.DECREMENT_CHAT_LIST_COUNTERS]: (state, id) => {
    state.chat_list.forEach((e) => {
      if (e.pk === id) {
        e.unread_amount--
      }
    })
  },
  [CHAT_LIST.MUTATIONS.REPLACE_CHAT]: (state, chat) => {
    const index = state.chat_list.findIndex((c) => c.pk === chat.pk)
    if (index !== -1) {
      state.chat_list.splice(index, 1, chat)
    }
  },
  [CHAT_LIST.MUTATIONS.INCREMENT_CHAT_DATE]: (state, chatId) => {
    const chat = state.chat_list.find((chat) => chat.pk === chatId)
    if (chat) {
      chat.updated_at = new Date().toISOString()
    }
  }
}

const actions = {
  async [CHAT_LIST.ACTIONS.GET_CHAT_LIST]({commit}) {
    try {
      let res = await network.getModeratorChats(showAllChats ? 1 : 0)
      const chats = res.res.chats

      commit(CHAT_LIST.MUTATIONS.SET_CHAT_LIST, chats)
    } catch (e) {
      console.log(e)
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
