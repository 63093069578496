<template>
  <form class="info-form" @submit.prevent="save">
    <transition name="fade">
      <Spinner v-if="loading || (firstLoading && !form.name && !form.email)"/>
    </transition>

    <BaseInput v-model="form.name" label="Full name" placeholder="Type name"/>
    <BaseInput v-model="form.email" label="E-mail" placeholder="Type email"/>
    <div class="actions">
      <BaseButton :disabled="!isValid" hovered="false">
        Save
      </BaseButton>
    </div>
  </form>
</template>

<script>
import {mapGetters} from 'vuex'
import BaseInput from '@/components/BaseInput'
import BaseButton from '@/components/BaseButton'
import Spinner from '@/components/Spinner'
import {PROFILE} from '@/store/types'

export default {
  name: 'InfoForm',
  components: {
    BaseInput,
    BaseButton,
    Spinner
  },
  data: () => ({
    form: {
      name: '',
      email: ''
    },
    loading: false,
    firstLoading: true
  }),
  computed: {
    ...mapGetters({
      profileInfo: PROFILE.GETTERS.INFO
    }),
    isValid() {
      const formIsUpdated =
        this.profileInfo.name !== this.form.name ||
        this.profileInfo.email !== this.form.email

      const emailIsValid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
        this.form.email
      )

      return (
        emailIsValid &&
        this.form.name.trim() &&
        formIsUpdated &&
        this.form.name.length <= 100
      )
    }
  },
  watch: {
    profileInfo: {
      immediate: true,
      handler(v) {
        this.form = {
          name: v.name,
          email: v.email
        }
        // для анимации loader при первой загрузки окна пока поля временно пустые
        if (this.form.name && this.form.email) {
          this.firstLoading = false
        }
      }
    }
  },
  methods: {
    async save() {
      if (this.isValid) {
        this.loading = true
        await this.$store.dispatch(PROFILE.ACTIONS.SAVE_INFO, this.form)
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.info-form {
  padding-top: 30px;
  position: relative;
}

.base-button {
  width: 120px;
}

.actions {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 480px) {
  .info-form {
    padding-top: 20px;
  }
  .actions {
    justify-content: center;
  }
}
</style>
