<template>
  <div class="connection-fail-wrapper">
    <div class="connection-fail">
      Internet connection found
      <img src="~@/assets/internet.svg" class="icon" />
    </div>
  </div>
</template>

<script>
export default {
  name: `ConnectionFail`
}
</script>

<style scoped>
.connection-fail-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
.connection-fail {
  height: 40px;
  border-radius: 8px;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 12px;
  line-height: 1;
  color: #727272;
  width: 100%;
  max-width: 930px;
  margin-top: 30px;
  margin-bottom: 20px;
}
.icon {
  width: 21px;
  height: 16px;
  margin-left: 10px;
}
</style>
