<template>
  <div v-if="images.length" class="pet-images">
    <div
      class="current-image"
      :style="{ backgroundImage: `url(${images[current]})` }"
    />
    <div class="images">
      <div
        v-for="(image, index) in images"
        :key="image"
        :class="{ image: true, selected: index === current }"
        :style="{ backgroundImage: `url(${image})` }"
        @click="current = index"
      />
    </div>
  </div>
</template>

<script>
import {PET_DIALOG} from '@/store/types'

export default {
  name: `PetImagesList`,
  computed: {
    passport() {
      return this.$store.getters[PET_DIALOG.GETTERS.PASSPORT]
    },
    images() {
      let passport = this.$store.getters[PET_DIALOG.GETTERS.PASSPORT];
      if (!passport) {
        return []
      }
      return passport.photos.map((image) =>
        `${image.url}`
      )
    }
  },
  data: () => ({
    current: 0
  })
}
</script>

<style lang="scss" scoped>
.pet-images {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 264px;
  margin-right: 30px;
}

.current-image {
  height: 333px;
  margin-bottom: 10px;
  border-radius: 7px;
  background-color: #eee;
  background-size: cover;
  background-position: center;
}

.images {
  display: flex;
  flex-wrap: wrap;
}

.image {
  width: 60px;
  height: 60px;
  cursor: pointer;
  border-radius: 7px;
  border: 2px solid #fff;
  background-color: #eee;
  background-size: cover;
  background-position: center;
  margin-right: 6px;
  margin-bottom: 6px;

  &.selected {
    border: 2px solid #00aa8d;
  }
}
</style>
