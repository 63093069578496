<template>
  <BaseLayout>
    <ProfileHeader />
    <div class="profile-content common-wrapper">
      <div class="forms">
        <InfoForm />
        <LocationForm />
        <PasswordForm />
      </div>
      <div class="photo">
        <ProfileImageForm />
        <LogoutButton />
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/layout/Base'
import ProfileHeader from './content/ProfileHeader'
import InfoForm from './content/InfoForm'
import PasswordForm from './content/PasswordForm'
import ProfileImageForm from './content/ProfileImageForm'
import LogoutButton from '@/views/profile/content/LogoutButton'
import LocationForm from '@/views/profile/content/location-form/LocationForm'
export default {
  name: 'Profile',
  components: {
    LocationForm,
    BaseLayout,
    ProfileHeader,
    InfoForm,
    PasswordForm,
    ProfileImageForm,
    LogoutButton
  }
}
</script>

<style lang="scss" scoped>
.forms {
  width: 100%;
  margin-right: 110px;
}
.profile-content {
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
  flex-shrink: 0;
}
.photo {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}

@media screen and (max-width: 920px) {
  .forms {
    margin-right: 30px;
  }
}
@media screen and (max-width: 768px) {
  .forms {
    margin-right: 70px;
  }
}
@media screen and (max-width: 600px) {
  .forms {
    margin-right: 20px;
  }
}
@media screen and (max-width: 480px) {
  .profile-content {
    display: block;
    // flex-direction: column;
  }
  .forms {
    margin-right: 0;
  }
  .photo {
    align-items: center;
  }
}
</style>
