<template>
  <div class="access-to-devices-dialog">
    <div class="dialog">
      <div class="title">Error</div>
      <img
        src="@/assets/video-chat/device-not-available.svg"
        class="camera-icon"
      />
      <div class="description">
        To make video calls you need to accept video and audio permissions
      </div>
      <div class="action">
        <BaseButton class="button" @click="closeAllChats">Cancel</BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/BaseButton'
import { VIDEO_CHATS } from '@/store/types'
export default {
  components: {
    BaseButton
  },
  methods: {
    closeAllChats() {
      this.$store.dispatch(VIDEO_CHATS.ACTIONS.CLOSE_ALL)
    }
  }
}
</script>

<style scoped>
.access-to-devices-dialog {
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}
.dialog {
  width: 100%;
  max-width: 420px;
  padding: 30px 0;
  border-radius: 8px;
  box-shadow: 1px 2px 16px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.title {
  font-family: ProximaNova;
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  color: #000000;
  margin-bottom: 20px;
}
.camera-icon {
  width: 58px;
  height: 59px;
  margin-bottom: 20px;
}
.description {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
  text-align: center;
  color: #000000;
  margin-bottom: 20px;
  width: 100%;
  max-width: 279px;
}
.button {
  width: 120px;
}
</style>
