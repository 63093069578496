<template>
  <div class="tooltip-lis-item" v-on="$listeners">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'TooltipLisItem'
}
</script>

<style lang="scss" scoped>
.tooltip-lis-item {
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  color: #00aa8d;
  padding: 13px 20px;
  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}
</style>
