<template>

  <div class="chat-container">

    <div class="flex-container">
      <div class="wrap">
        <div class="avatar">
          <Avatar
            :image="question.owner.image_url"
            :full-name="question.owner.name"
          />
          <div style="height: 4px"></div>

        </div>
        <div class="message">
          <p style="font-weight: 700; font-size: 16px; width: 300px">Question
            ({{ formatDate(question.date_create) }}):</p>
          <p><b>{{ question.owner.name }}</b>: {{ question.client_request }}</p>
        </div>

      </div>


    </div>
    <div>
      <div style="height: 24px !important;"></div>
      <label style="font-weight: 700; font-size: 16px">Your answer:</label>
      <div style="height: 8px !important;"></div>
      <textarea class="textarea-answer" rows="4" v-model="answer"></textarea>
      <div style="display: flex; justify-content: flex-end">
        <BaseButton style="width: 100px;" class="passport-button"
                    @click="openPassport">Pet
        </BaseButton>
        <div style="width: 4px"></div>
        <BaseButton style="width: 150px; " @click="complete">Send Answer</BaseButton>
      </div>
    </div>
    <hr/>
  </div>
</template>

<script>
import Avatar from '@/components/Avatar.vue'
import BaseButton from '@/components/BaseButton'
import {COMMON, PET_DIALOG, QUESTIONS} from '@/store/types'

export default {
  components: {Avatar, BaseButton}, //{Avatar, BaseButton},
  props: {
    question: {
      type: [Object, null],
      default: null
    },

  },
  data: () => ({
    currentTime: null,
    timer: null,
    answer: null
  }),
  computed: {


    timeRemaining() {
      if (this.currentTime > 59) return '01:00'
      return this.currentTime < 10
        ? '00:0' + this.currentTime
        : '00:' + this.currentTime
    },
    isReconnecting() {
      return this.$store.getters[COMMON.GETTERS.RECONNECTING]
    }
  },
  watch: {
    currentTime() {
      if (this.currentTime === 0) this.stopTimer()
    }
  },
  mounted() {
    this.currentTime = Math.floor(this.finished)
    this.startTimer()
  },
  destroyed() {
    this.stopTimer()
  },
  methods: {
    formatDate(dt_text) {

      let dt = new Date(dt_text)
      let res = `${dt.getDate().toString().padStart(2, '0')}.${dt.getMonth().toString().padStart(2, '0')}.${dt.getFullYear()}`
      res += ` ${dt.getHours().toString().padStart(2, '0')}:${dt.getMinutes().toString().padStart(2, '0')}`
      return res

    },
    complete() {
      if (this.answer !== null) {
        this.$store.dispatch(QUESTIONS.ACTIONS.COMPLETE, {
          question: this.question,
          answer: this.answer
        })
      }
    },
    openPassport() {
      let pet_id = this.question.pet ? this.question.pet.id : null
      this.$store.dispatch(PET_DIALOG.ACTIONS.OPEN_PASSPORT, pet_id)
    },
    startTimer() {
      this.timer = setInterval(() => this.currentTime--, 1000)
    },
    stopTimer() {
      clearTimeout(this.timer)
    },


  }
}
</script>

<style lang="scss" scoped>
.chat-container {
  max-width: 930px;
  margin: 0 auto;
}

.flex-container {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
}

.textarea-answer {
  border: gray 1px solid;
  padding: 8px;
  width: 100%;
  border-radius: 4px;

}

.textarea-answer:focus {
  outline: none !important;
  border-color: #00aa8d !important;

}

.passport-button {
  background: gray;
  border: gray 1px solid;

}

.passport-button:hover {
  background: white;
  border: gray 1px solid;
  color: gray !important;
}

// name, avatar
.wrap {
  display: flex;
  align-items: center;
}

.avatar {
  height: 50px;
  width: 50px;
  margin-right: 15px;
}

.message {
  width: 190px;
}

.pet-button {
  width: 81px;
  margin-left: 20px;
}

.from {
  font-size: 12px;
  line-height: 2.17;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.name {
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.date-request {
  display: flex;
  align-items: center;
  opacity: 0.6;
  font-size: 12px;
  line-height: 1;
  color: #1e272e;
  margin-left: 10px;
}

// buttons
.actions {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.not-accept {
  width: 159px;
  margin-left: 20px;
}

.accept {
  width: 120px;
  margin-left: 20px;
}

hr {
  background: #ebebeb;
  border: none;
  height: 2px;
  margin: 15px 0;
}

@media screen and (max-width: 1288px) {
  .chat-container {
    padding: 0 34px;
  }
  .flex-container {
    padding-left: 0;
  }
}

@media screen and (max-width: 1030px) {
  .message {
    width: 170px;
  }
}

@media screen and (max-width: 1000px) {
  .not-accept {
    width: 139px;
  }
  .accept {
    width: 100px;
  }
}

@media screen and (max-width: 930px) {
  .message {
    width: 120px;
  }
}

@media screen and (max-width: 880px) {
  .message {
    width: 100px;
  }
}

@media screen and (max-width: 860px) {
  .not-accept {
    width: 120px;
  }
  .accept {
    width: 70px;
  }
}

@media screen and (max-width: 810px) {
  .chat-container {
    padding: 0 15px;
  }
}

@media screen and (max-width: 768px) {
  .chat-container {
    padding: 0 34px;
  }
  .message {
    width: 190px;
  }
  .accept {
    width: 120px;
  }
  .not-accept {
    width: 159px;
  }
}

@media screen and (max-width: 700px) {
  .message {
    width: 170px;
  }
  .not-accept {
    width: 139px;
  }
  .accept {
    width: 100px;
  }
}

@media screen and (max-width: 640px) {
  .message {
    width: 100px;
  }
  .not-accept {
    width: 120px;
  }
  .accept {
    width: 70px;
  }
}

@media screen and (max-width: 520px) {
  .flex-container {
    flex-wrap: wrap;
    position: relative;
  }
  .actions {
    margin-left: 0;
    margin-top: 15px;
    width: 100%;
  }
  .not-accept {
    width: 150px;
  }
  .accept {
    width: 120px;
  }
  .message {
    width: auto;
  }
  .date-request {
    position: absolute;
    right: 0;
    top: 13px;
    align-items: flex-start;
  }
  .from,
  .name {
    white-space: normal;
  }
}

@media screen and (max-width: 480px) {
  .chat-container {
    padding: 0 20px;
  }
}
</style>
