<template>
  <div :class="{ 'video-chat': true, 'full-screen-chat': chat.isFullScreen }">
    <div class="actions">
      <div class="full-screen" @click="toggleFullScreen" />
      <div class="close" @click="closeChat" />
    </div>

    <div :id="`${chat.id}-remote-stream`" class="remote" />

    <div class="self-video">
      <div :id="`${chat.id}-local-stream`" class="local" />

      <div v-if="timeLeft" class="chat-timer">
        <img src="@/assets/video-chat/clock.svg" class="clock" />
        <div class="timer-text">
          {{ chatLeftText }}
        </div>
      </div>
    </div>

    <div class="close-button-wrapper">
      <div class="button cancel" @click="closeChat" />
    </div>
    <div
      :class="{ 'volume-switcher': true, enabled: !muted }"
      @click="toggleSound"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import numerals from '@/lib/helpers/numerals'
import { videoChats } from '@/lib/video-chats/video-call'
import { VIDEO_CHATS } from '@/store/types'

export default {
  name: `VideoChat`,
  props: {
    chat: {
      type: Object,
      default: () => ({}) // {id, isFullScreen, startDate}
    }
  },
  data: () => ({
    timerTimeout: null,
    muted: false,

    timeLeft: null, // dayjs
    textHours: [`hours`, `hour`, `hours`],
    textMinutes: [`minutes`, `minute`, `minutes`],
    overTimeInMinutes: 30 // 30 min to left chat
  }),
  computed: {
    chatLeftText() {
      const minutes = +this.timeLeft.get(`minute`) + 1
      const minutesNumerals = numerals(minutes, this.textMinutes)

      return `${minutes} ${minutesNumerals} left to chat`
    }
  },
  updated() {
    const videoChat = videoChats.list[this.chat.id]
    if (videoChat.isMuted() !== this.muted) {
      this.muted = videoChat.isMuted()
    }
  },
  async mounted() {
    const videoChat = videoChats.list[this.chat.id]
    await videoChat.join()
    this.muted = videoChat.isMuted()

    videoChat.playRemote()

    this.timerTimeout = setInterval(() => {
      this.updateTimeLeft()
    }, 1000)
    this.updateTimeLeft()
  },
  destroyed() {
    clearInterval(this.timerTimeout)
    this.timerTimeout = null
  },
  methods: {
    toggleSound() {
      const videoChat = videoChats.list[this.chat.id]
      videoChat.toggleSound()
      this.muted = videoChat.isMuted()
    },
    updateTimeLeft() {
      this.timeLeft = dayjs(this.chat.endDate).subtract(dayjs())
    },
    closeChat() {
      videoChats.list[this.chat.id].close()
    },
    toggleFullScreen() {
      this.$store.commit(VIDEO_CHATS.MUTATIONS.TOGGLE_FULL_SCREEN, this.chat)
    }
  }
}
</script>

<style lang="scss" scoped>
.self-video {
  position: relative;
  display: flex;
  align-items: flex-end;
  padding-left: 10px;
  padding-bottom: 10px;
  width: 100%;
  height: 100%;
}
.local {
  background: #eee;
  width: 71px;
  height: 99px;
  flex-basis: 71px;
  position: relative;
  overflow: hidden;
  border-radius: 7px;
  margin-right: 21px;
  display: flex;
  align-items: flex-end;
}
.video-chat {
  color: #fff;
  margin-top: 20px;
  width: 360px;
  height: 230px;
  border-radius: 8px;
  background-color: #000;
  position: relative;
  transition: all 300ms;
  display: flex;
  align-items: flex-end;

  &.full-screen-chat {
    position: fixed;
    border-radius: 0;
    margin-top: 0;
    width: 100vw;
    max-width: unset;
    height: 100vh;
    z-index: 1000;
    left: 0;
    bottom: 0;

    .local {
      position: absolute;
      left: 20px;
      bottom: 20px;
      flex-basis: 150px;
      width: 150px;
      height: 210px;
    }

    .volume-switcher {
      right: unset;
      bottom: 52px;
      left: 50%;
      transform: translateX(-50%);
      margin-left: 70px;
    }
    .chat-timer {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 20px;
      bottom: unset;
    }
    .actions {
      padding: 20px;
    }
    .full-screen {
      width: 26px;
      height: 20px;
      background: url('~@/assets/video-chat/toggle-full-screen.svg') center
        no-repeat;
      background-size: cover;
    }
    .close-button-wrapper {
      visibility: visible;
      opacity: 1;
    }
    .remote {
      width: 100%;
      height: 100%;
      max-width: 100vw;
      max-height: 100vh;
    }
  }
}

.chat-timer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.clock {
  margin-right: 10px;
  width: 26px;
  height: 30px;
}

.timer-text {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71;
  color: #ffffff;
  white-space: nowrap;
}

.button {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  cursor: pointer;

  &.cancel {
    background: url('~@/assets/video-chat/cancel.svg') center no-repeat;
    background-size: 35px;
    background-color: #ee5245;
  }

  &:hover {
    opacity: 0.8;
  }
}
.close-button-wrapper {
  opacity: 0;
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.volume-switcher,
.full-screen,
.close {
  cursor: pointer;
}
.volume-switcher {
  position: absolute;
  right: 15px;
  bottom: 12px;
  width: 18px;
  height: 26px;
  background: url('~@/assets/video-chat/microphone-disabled.svg') center
    no-repeat;
  background-size: cover;

  &.enabled {
    background: url('~@/assets/video-chat/microphone-enabled.svg') center
      no-repeat;
    background-size: cover;
  }
}
.full-screen {
  width: 26px;
  height: 20px;
  background: url('~@/assets/video-chat/full-screen.svg') center no-repeat;
  background-size: cover;
}
.actions {
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  padding: 12px 15px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 1;
}
.close {
  width: 14px;
  height: 14px;
  background: url('~@/assets/video-chat/close.svg') center no-repeat;
  background-size: cover;
}
.remote {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: absolute;
  & /deep/ video {
    flex: 1;
  }
}

@-moz-document url-prefix() {
  .remote {
    max-width: 230px;
    max-height: 360px;
    transform: rotate(-90deg);
    margin: 0 auto;
  }

  .video-chat {
    &.full-screen-chat {
      .remote {
        width: 100%;
        height: 100%;
        max-width: 100vh !important;
        max-height: 100vw !important;
      }
    }
  }
}

@supports (-webkit-hyphens: none) {
  .video-chat {
    &.full-screen-chat {
      .remote {
        position: absolute;
      }
    }
  }
}
</style>
