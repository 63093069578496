<template>
  <div class="map-container">
    <GmapMap :center="position" :zoom="zoom" map-type-id="terrain" class="map">
      <GmapMarker v-if="hasCoords" :position="position" />
    </GmapMap>
  </div>
</template>

<script>
export default {
  name: `Map`,
  props: {
    location: {
      type: Object,
      default: () => ({
        address: ``,
        lat: 0,
        lon: 0
      })
    }
  },
  computed: {
    hasCoords() {
      return this.location.lat && this.location.lon
    },
    position() {
      return { lat: this.location.lat || 0, lng: this.location.lon || 0 }
    },
    zoom() {
      if (this.location.lat && this.location.lon) {
        return 7
      }
      return 2
    }
  }
}
</script>

<style scoped>
.map-container {
  height: 269px;
  background: #f6f6f6;
  margin-top: 20px;
  position: relative;
}
.map {
  width: 100%;
  height: 100%;
}
</style>
