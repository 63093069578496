export const PROFILE = {
  GETTERS: {
    INFO: `PROFILE/INFO`,
    ERROR: `PROFILE/ERROR`,
    SUCCESS: 'PROFILE/SUCCESS'
  },
  MUTATIONS: {
    SET_INFO: `PROFILE/SET_INFO`,
    PASSWORD_ERROR: `PROFILE/PASSWORD_ERROR`,
    PASSWORD_SUCCESS: `PROFILE/PASSWORD_SUCCESS`
  },
  ACTIONS: {
    LOAD_INFO: `PROFILE/LOAD_INFO`,
    SAVE_INFO: `PROFILE/SAVE_INFO`,
    CHANGE_PASSWORD: `PROFILE/CHANGE_PASSWORD`,
    UPLOAD_IMAGE: `PROFILE/UPLOAD_IMAGE`
  }
}

export const AUTH = {
  GETTERS: {
    GET_AUTH: `AUTH/GET_AUTH`
  },
  MUTATIONS: {
    IS_AUTH: `AUTH/IS_AUTH`,
    SET_TOKEN: `AUTH/SET_TOKEN`,
    AUTH_ERROR: `AUTH/AUTH_ERROR`
  },
  ACTIONS: {
    LOGOUT: `AUTH/LOGOUT`,
    LOGIN: `AUTH/LOGIN`,
    RESTORE_PASSWORD: `AUTH/RESTORE_PASSWORD`
  }
}

export const SIDEBAR_OPEN = {
  GETTERS: {
    GET_IS_NAV_OPEN: `SIDEBAR_OPEN/GET_IS_NAV_OPEN`,
    MOBILE_CLICK: `SIDEBAR_OPEN/MOBILE_CLICK`
  },
  MUTATIONS: {
    TOGGLE_NAV: `SIDEBAR_OPEN/TOGGLE_NAV`,
    MOBILE_CLICK: `SIDEBAR_OPEN/MOBILE_CLICK`
  },
  ACTIONS: {}
}

export const CHAT_LIST = {
  GETTERS: {
    GET_CHAT_LIST: `CHAT_LIST/GET_CHAT_LIST`
  },
  MUTATIONS: {
    SET_CHAT_LIST: `CHAT_LIST/SET_CHAT_LIST`,
    CHANGE_SHOW_ALL: `CHAT_LIST/CHANGE_SHOW_ALL`,
    ADD_CHAT: `CHAT_LIST/ADD_CHAT`,
    INCREMENT_CHAT_LIST_COUNTERS: `CHAT_LIST/INCREMENT_CHAT_LIST_COUNTERS`,
    DECREMENT_CHAT_LIST_COUNTERS: `CHAT_LIST/DECREMENT_CHAT_LIST_COUNTERS`,
    REPLACE_CHAT: `CHAT_LIST/REPLACE_CHAT`,
    INCREMENT_CHAT_DATE: `CHAT_LIST/INCREMENT_CHAT_DATE`,
    CLOSE_CHAT: `CHAT_LIST/CLOSE_CHAT`
  },
  ACTIONS: {
    GET_CHAT_LIST: `CHAT_LIST/GET_CHAT_LIST`
  }
}

export const QUESTIONS = {
  GETTERS: {
    GET_QUESTIONS: `QUESTIONS/GET_QUESTIONS`,
    QUESTION_BY_ID: `QUESTIONS/QUESTION_BY_ID`
  },
  MUTATIONS: {
    SET_QUESTIONS: `QUESTIONS/SET_QUESTIONS`,
    ADD_QUESTION: `QUESTIONS/ADD_QUESTION`,
    REMOVE: `QUESTIONS/REMOVE`
  },
  ACTIONS: {
    COMPLETE: `QUESTIONS/COMPLETE`
  }
  /*
  MUTATIONS: {
    SET_CHAT_REQUESTS: `CHAT_REQUESTS/SET_CHAT_REQUESTS`,
    ADD_CHAT_REQUESTS: `CHAT_REQUESTS/ADD_CHAT_REQUESTS`,
    SHOW_REDIRECT_BUTTON: `CHAT_REQUESTS/SHOW_REDIRECT_BUTTON`,
    REMOVE_ALL_REQUESTS: `CHAT_REQUESTS/REMOVE_ALL_REQUESTS`,
    ENABLE_REQUEST_ACCEPTION: `CHAT_REQUESTS/ENABLE_REQUEST_ACCEPTION`,
    REMOVE_REQUEST: `CHAT_REQUESTS/REMOVE_REQUEST`
  },
  ACTIONS: {
    DO_NOT_ACCEPT_REQUESTS: `CHAT_REQUESTS/DO_NOT_ACCEPT_REQUESTS`,
    ACCEPT_REQUEST: `CHAT_REQUESTS/ACCEPT_REQUEST`,
    CANCEL_NOT_ACCEPTED: `CHAT_REQUESTS/CANCEL_NOT_ACCEPTED`
  }*/
}

export const CHAT_REQUESTS = {
  GETTERS: {
    GET_CHAT_REQUESTS: `CHAT_REQUESTS/GET_CHAT_REQUESTS`,
    REQUEST_BY_ID: `CHAT_REQUESTS/REQUEST_BY_ID`,
    ISSET_ACCEPTED_CHAT_REQUEST: `CHAT_REQUESTS/ISSET_ACCEPTED_CHAT_REQUEST`,
    HAS_ACCEPTED: `CHAT_REQUESTS/HAS_ACCEPTED`
  },

  MUTATIONS: {
    SET_CHAT_REQUESTS: `CHAT_REQUESTS/SET_CHAT_REQUESTS`,
    ADD_CHAT_REQUESTS: `CHAT_REQUESTS/ADD_CHAT_REQUESTS`,
    SHOW_REDIRECT_BUTTON: `CHAT_REQUESTS/SHOW_REDIRECT_BUTTON`,
    REMOVE_ALL_REQUESTS: `CHAT_REQUESTS/REMOVE_ALL_REQUESTS`,
    ENABLE_REQUEST_ACCEPTION: `CHAT_REQUESTS/ENABLE_REQUEST_ACCEPTION`,
    REMOVE_REQUEST: `CHAT_REQUESTS/REMOVE_REQUEST`
  },
  ACTIONS: {
    DO_NOT_ACCEPT_REQUESTS: `CHAT_REQUESTS/DO_NOT_ACCEPT_REQUESTS`,
    ACCEPT_REQUEST: `CHAT_REQUESTS/ACCEPT_REQUEST`,
    CANCEL_NOT_ACCEPTED: `CHAT_REQUESTS/CANCEL_NOT_ACCEPTED`
  }
}

export const CHAT_IMAGES_SLIDER = {
  GETTERS: {
    SHOW_SLIDER: `CHAT_IMAGES_SLIDER/SHOW_SLIDER`,
    CURRENT_IMAGE: `CHAT_IMAGES_SLIDER/CURRENT_IMAGE`,
    IMAGES: `CHAT_IMAGES_SLIDER/IMAGES`
  },
  MUTATIONS: {
    SET_SLIDER_VISIBILITY: `CHAT_IMAGES_SLIDER/SET_SLIDER_VISIBILITY`,
    NEXT: `CHAT_IMAGES_SLIDER/NEXT`,
    PREV: `CHAT_IMAGES_SLIDER/PREV`,
    SET_IMAGES: `CHAT_IMAGES_SLIDER/SET_IMAGES`,
    SET_CURRENT_INDEX: `CHAT_IMAGES_SLIDER/SET_CURRENT_INDEX`
  }
}

export const MESSAGES = {
  GETTERS: {
    MESSAGES: `MESSAGES/GET_MESSAGES`,
    CHAT: `MESSAGES/CHAT`,
    NEXT_LIST_URL: `MESSAGES/NEXT_LIST_URL`,
    NEXT_FETCHING: `MESSAGES/NEXT_FETCHING`,
    SENDING: `MESSAGES/SENDING`
  },
  MUTATIONS: {
    SET_MESSAGES: `MESSAGES/SET_MESSAGES`,
    SET_NEXT_LIST_URL: `MESSAGES/SET_NEXT_LIST_URL`,
    SET_SENDING: `MESSAGES/SET_SENDING`,
    ADD_MESSAGES: `MESSAGES/ADD_MESSAGES`,
    SET_CURRENT_CHAT: `MESSAGES/SET_CURRENT_CHAT`,
    CLEAR_CURRENT_CHAT: `MESSAGES/CLEAR_CURRENT_CHAT`,
    ADD_MESSAGE: `MESSAGES/ADD_MESSAGE`,
    SET_NEXT_FETCHING: `MESSAGES/SET_NEXT_FETCHING`,
    READ_MESSAGE: `MESSAGES/READ_MESSAGE`,
    CLOSE_CHAT: `MESSAGES/CLOSE_CHAT`,
    TOGGLE_CURRENT_CHAT_TYPE: `MESSAGES/TOGGLE_CURRENT_CHAT_TYPE`
  },
  ACTIONS: {
    CLOSE_CURRENT_CHAT: `MESSAGES/CLOSE_CURRENT_CHAT`,
    CANCEL_CURRENT_CHAT: `MESSAGES/CANCEL_CURRENT_CHAT`,
    CLOSE_CHAT: `MESSAGES/CLOSE_CHAT`,
    LOAD_CHAT_PAGE: `MESSAGES/LOAD_CHAT_PAGE`,
    LOAD_NEXT: `MESSAGES/LOAD_NEXT`,
    LOAD_CURRENT_CHAT: `MESSAGES/LOAD_CURRENT_CHAT`,
    LOAD_MESSAGES: `MESSAGES/LOAD_MESSAGES`,
    SEND_MESSAGE: `MESSAGES/SEND_MESSAGE`,
    READ_MESSAGES: `MESSAGES/READ_MESSAGES`,
    READ_CURRENT_MESSAGES: `MESSAGES/READ_CURRENT_MESSAGES`
  }
}

export const CHAT_RESUME = {
  GETTERS: {
    RESUME_IS_CLEAR: `CHAT_RESUME/RESUME_IS_CLEAR`,
    FORM_VISIBILITY: `CHAT_RESUME/FORM_VISIBILITY`,
    FORM: `CHAT_RESUME/FORM`,
    SAVING: `CHAT_RESUME/SAVING`,
    MODAL_TYPE: `CHAT_RESUME/MODAL_TYPE`
  },
  MUTATIONS: {
    SET_FORM_VISIBILITY: `CHAT_RESUME/SET_FORM_VISIBILITY`,
    SET_MODAL_TYPE: `CHAT_RESUME/SET_MODAL_TYPE`,
    SET_RESUME_SAVING: `CHAT_RESUME/SET_RESUME_SAVING`,
    SET_FORM: `CHAT_RESUME/SET_FORM`,
    SET_FORM_ITEM: `CHAT_RESUME/SET_FORM_ITEM`,
    CLEAR_FORM: `CHAT_RESUME/CLEAR_FORM`
  },
  ACTIONS: {
    SAVE_RESUME: `CHAT_RESUME/SAVE_RESUME`,
    CLOSE_BY_MISTAKE: `CHAT_RESUME/CLOSE_BY_MISTAKE`
  }
}

export const CHAT_COMPLETE = {
  GETTERS: {
    RESUME_IS_CLEAR: `CHAT_COMPLETE/RESUME_IS_CLEAR`,
    FORM_VISIBILITY: `CHAT_COMPLETE/FORM_VISIBILITY`,
    FORM: `CHAT_COMPLETE/FORM`,
    SAVING: `CHAT_COMPLETE/SAVING`,
    MODAL_TYPE: `CHAT_COMPLETE/MODAL_TYPE`
  },
  MUTATIONS: {
    SET_FORM_VISIBILITY: `CHAT_COMPLETE/SET_FORM_VISIBILITY`,
    SET_MODAL_TYPE: `CHAT_COMPLETE/SET_MODAL_TYPE`,
    SET_RESUME_SAVING: `CHAT_COMPLETE/SET_RESUME_SAVING`,
    SET_FORM: `CHAT_COMPLETE/SET_FORM`,
    SET_FORM_ITEM: `CHAT_COMPLETE/SET_FORM_ITEM`,
    CLEAR_FORM: `CHAT_COMPLETE/CLEAR_FORM`
  },
  ACTIONS: {
    SAVE_RESUME: `CHAT_COMPLETE/SAVE_RESUME`,
    CLOSE_BY_MISTAKE: `CHAT_COMPLETE/CLOSE_BY_MISTAKE`,
    CLOSE_BY_COMPLETE: `CHAT_COMPLETE/CLOSE_BY_COMPLETE`
  }
}

export const NOTIFICATIONS = {
  GETTERS: {
    LIST: `NOTIFICATIONS/LIST`
  },
  MUTATIONS: {
    ADD: `NOTIFICATIONS/ADD`,
    DELETE_BY_KEY: `NOTIFICATIONS/DELETE_BY_KEY`
  },
  ACTIONS: {
    NOTIFY: `NOTIFICATIONS/NOTIFY`
  }
}

export const COMMON = {
  GETTERS: {
    WINDOW_IN_FOCUS: `COMMON/WINDOW_IN_FOCUS`,
    RECONNECTING: `COMMON/RECONNECTING`
  },
  MUTATIONS: {
    SET_WINDOW_IN_FOCUS: `COMMON/SET_WINDOW_IN_FOCUS`,
    SET_RECONNECTING: `COMMON/SET_RECONNETING`
  }
}

export const VIDEO_CHATS = {
  GETTERS: {
    CHATS: `VIDEO_CHATS/CHATS`,
    REQUESTS: `VIDEO_CHATS/REQUESTS`,
    REQUEST_BY_CHAT: `VIDEO_CHATS/REQUEST_BY_CHAT`
  },
  MUTATIONS: {
    SET_CHAT_LIST: `VIDEO_CHATS/SET_CHAT_LIST`,
    ADD_CHAT: `VIDEO_CHATS/ADD_CHAT`,
    REMOVE_CHAT: 'VIDEO_CHATS/REMOVE_CHAT',
    TOGGLE_FULL_SCREEN: 'VIDEO_CHATS/TOGGLE_FULL_SCREEN',
    REMOVE_REQUEST: 'VIDEO_CHATS/REMOVE_REQUEST',
    REMOVE_REQUEST_BY_CHAT: 'VIDEO_CHATS/REMOVE_REQUEST_BY_CHAT',
    ADD_REQUEST: 'VIDEO_CHATS/ADD_REQUEST',
  },
  ACTIONS: {
    CLOSE_REQUEST: 'VIDEO_CHATS/CLOSE_REQUEST',
    CLOSE_ALL: `VIDEO_CHATS/CLOSE_ALL`
  }
}

export const PET_DIALOG = {
  GETTERS: {
    PASSPORT: `VIDEO_CHATS/PASSPORT`,
    PASSPORT_FETCHING: `VIDEO_CHATS/PASSPORT_FETCHING`,
    PASSPORT_DIALOG_VISIBILITY: `VIDEO_CHATS/PASSPORT_DIALOG_VISIBILITY`,
    EMPTY_PASSPORT_DIALOG_VISIBILITY: `VIDEO_CHATS/EMPTY_PASSPORT_DIALOG_VISIBILITY`
  },
  MUTATIONS: {
    SET_PASSPORT: `VIDEO_CHATS/SET_PASSPORT`,
    SET_PASSPORT_FETCHING: `VIDEO_CHATS/SET_PASSPORT_FETCHING`,

    SET_EMPTY_PASSPORT_DIALOG_VISIBILITY: `VIDEO_CHATS/SET_EMPTY_PASSPORT_DIALOG_VISIBILITY`,
    SET_PASSPORT_DIALOG_VISIBILITY: `VIDEO_CHATS/SET_PASSPORT_DIALOG_VISIBILITY`
  },
  ACTIONS: {
    OPEN_PASSPORT: 'VIDEO_CHATS/OPEN_PASSPORT'
  }
}
