<template>
  <Header class="header">
    <slot />
  </Header>
</template>

<script>
import Header from '@/components/Header'
export default {
  name: 'ProfileHeader',
  components: { Header }
}
</script>

<style lang="scss" scoped>
img {
  height: 24px;
  margin-right: 20px;
}
a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000;
}
</style>
