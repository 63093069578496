<template>
  <div class="images-slider">
    <img src="~@/assets/slider/close.svg" class="close" @click="close"/>
    <img
      v-if="images.length > 1"
      src="~@/assets/slider/left.svg"
      class="prev"
      @click="prev"
    />
    <transition name="fade">
      <img v-if="showImage" :src="currentImage" class="image"/>
    </transition>
    <img
      v-if="images.length > 1"
      src="~@/assets/slider/right.svg"
      class="next"
      @click="next"
    />
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import {CHAT_IMAGES_SLIDER} from '@/store/types'

export default {
  name: 'ImagesSlider',
  data: () => ({
    showImage: true,
    scrollPosition: 0
  }),
  computed: {
    ...mapGetters({
      currentImage: CHAT_IMAGES_SLIDER.GETTERS.CURRENT_IMAGE,
      images: CHAT_IMAGES_SLIDER.GETTERS.IMAGES
    })
  },
  watch: {
    currentImage() {
      this.animateImage()
    }
  },
  mounted() {
    this.setBodyFixed()
    document.addEventListener('keydown', this.keyboardHandler)
  },
  beforeDestroy() {
    this.setBodyUnfixed()
    document.removeEventListener('keydown', this.keyboardHandler)
  },
  methods: {
    ...mapMutations({
      next: CHAT_IMAGES_SLIDER.MUTATIONS.NEXT,
      prev: CHAT_IMAGES_SLIDER.MUTATIONS.PREV
    }),
    close() {
      this.$store.commit(
        CHAT_IMAGES_SLIDER.MUTATIONS.SET_SLIDER_VISIBILITY,
        false
      )
    },
    keyboardHandler(e) {
      if (e.keyCode === 27) {
        this.close()
      }
      if (e.keyCode === 39) this.next()
      if (e.keyCode === 37) this.prev()
    },
    animateImage() {
      this.showImage = false
      setTimeout(() => (this.showImage = true), 300)
    },
    setBodyFixed() {
      this.scrollPosition =
        window.pageYOffset || document.documentElement.scrollTop
      //
      document.querySelector('.c-chat').style.overflowY = 'hidden'
      document.body.style.position = 'fixed'
      document.body.style.top = `${-this.scrollPosition}px`
      document.body.style.left = '0'
      document.body.style.right = '0'
    },
    setBodyUnfixed() {
      document.querySelector('.c-chat').style.overflowY = 'auto'
      document.body.style.position = ''
      document.body.style.top = ''
      document.body.style.left = ''
      document.body.style.right = ''
      window.scroll(0, this.scrollPosition)
    }
  }
}
</script>

<style lang="scss" scoped>
.images-slider {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.4);
}

.image {
  cursor: pointer;
  width: auto;
  height: auto;
  max-width: calc(90% - 90px);
  max-height: 90%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  background: #eee; //png
}

.prev,
.next,
.close {
  position: absolute;
  height: 45px;
  width: auto;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.prev,
.next,
.close,
.image {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.close {
  transform: unset;
  right: 20px;
  top: 20px;
  height: 30px;
}

.prev {
  left: 20px;
}

.next {
  right: 20px;
}
</style>
