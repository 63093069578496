var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"base-input"},[_c('label',{staticClass:"base-input_label"},[_vm._v(_vm._s(_vm.label))]),_c('input',_vm._g({staticClass:"base-input_input",class:{
      error_valid:
        _vm.authError || _vm.forgotPasswordError || (_vm.passwordError && _vm.error)
    },attrs:{"placeholder":_vm.placeholder,"type":_vm.type,"autocomplete":"on"},domProps:{"value":_vm.value}},{
      ..._vm.$listeners,
      change: (e) => _vm.$emit(`change`, e.target.value),
      input: (e) => _vm.$emit(`input`, e.target.value)
    }))])
}
var staticRenderFns = []

export { render, staticRenderFns }