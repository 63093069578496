import {CHAT_REQUESTS} from '@/store/types'
import socket from '@/lib/network/socket'
import config from '@/config'

const state = () => ({
  chat_requests: [],
  currentChatRequestId: +localStorage.getItem(config.CURRENT_CHAT_REQUEST_KEY),
  lastAcceptedRequestId: +localStorage.getItem(
    config.LAST_ACCEPTED_CHAT_REQUEST_ID_KEY
  )
})

const getters = {
  [CHAT_REQUESTS.GETTERS.HAS_ACCEPTED]: (state) =>
    state.chat_requests.find((request) => request.accepted),
  [CHAT_REQUESTS.GETTERS.GET_CHAT_REQUESTS]: (state) => state.chat_requests,
  [CHAT_REQUESTS.GETTERS.REQUEST_BY_ID]: (state) => (id) => function () {

    return state.chat_requests.find((request) => request.id === id)
  }(),
  [CHAT_REQUESTS.GETTERS.ISSET_ACCEPTED_CHAT_REQUEST]: (state) =>
    !!state.chat_requests.find((request) => !!request.redirectButtonShowed)
}

const mutations = {
  [CHAT_REQUESTS.MUTATIONS.ADD_CHAT_REQUESTS]: (state, ticket) => {
    const requests = state.chat_requests.filter(
      (request) => request.id !== ticket.id
    )

    ticket = {
      ...ticket,
      redirectButtonShowed: false,
      accepted: false,
      chat: null
    }

    state.chat_requests.splice(
      0,
      state.chat_requests.length,
      ticket,
      ...requests
    )
  },
  [CHAT_REQUESTS.MUTATIONS.SET_CHAT_REQUESTS]: (state, list) => {
    state.chat_requests = list.map((item) => ({
      ...item,
      redirectButtonShowed: false,
      accepted: false,
      chat: null
    }))
  },
  [CHAT_REQUESTS.MUTATIONS.SHOW_REDIRECT_BUTTON]: (state, ticket_finished) => {
    let id = ticket_finished.id
    let uid = ticket_finished.pk
    // console.log('cr', state.chat_requests, pk)
    let request = state.chat_requests.find(
      (request) => request.pk === parseInt(id)
    )
    state.chat_requests.forEach((el) => {

      if (el.id === id) {
        if (!(request)) {
          request = el
        }
      }
    })
    console.log('BUTTON!!!', request, uid)
    if (request) {
      request.redirectButtonShowed = true
      request.chat = uid
    }
  },
  [CHAT_REQUESTS.MUTATIONS.ENABLE_REQUEST_ACCEPTION]: (state, pk) => {
    const request = state.chat_requests.find((request) => request.pk === pk)
    if (request) {
      request.accepted = true
    }
  },
  [CHAT_REQUESTS.MUTATIONS.REMOVE_REQUEST]: (state, requestId) => {
    state.chat_requests = state.chat_requests.filter(
      (request) => request.id !== requestId
    )
  },
  [CHAT_REQUESTS.MUTATIONS.REMOVE_ALL_REQUESTS]: (state) => {
    state.chat_requests = []
  }


}

const actions = {
  [CHAT_REQUESTS.ACTIONS.ACCEPT_REQUEST]({commit}, id) {
    socket.send({
      ticket_accept: {ticket_pk: id, is_accepted: true}
    })

    commit(CHAT_REQUESTS.MUTATIONS.ENABLE_REQUEST_ACCEPTION, id)

  },
  [CHAT_REQUESTS.ACTIONS.DO_NOT_ACCEPT_REQUESTS]({commit}, id) {
    socket.send({
      ticket_accept: {ticket_pk: id, is_accepted: false}
    })

    commit(CHAT_REQUESTS.MUTATIONS.REMOVE_REQUEST, id)
  },
  [CHAT_REQUESTS.ACTIONS.CANCEL_NOT_ACCEPTED]({commit, getters}) {
    const requests = getters[CHAT_REQUESTS.GETTERS.GET_CHAT_REQUESTS]

    requests
      .filter((req) => !req.accepted)
      .forEach((req) => {
        socket.send({
          ticket_accept: {ticket_pk: req.id, is_accepted: false}
        })
        commit(CHAT_REQUESTS.MUTATIONS.REMOVE_REQUEST, req.id)
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
