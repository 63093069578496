<template>
  <div class="wrapper-send-message">
    <div class="chat-form">
      <Spinner v-if="messageSending" />
      <BaseTextarea
        ref="messageTextBox"
        v-model="message"
        placeholder="Message"
        type="text"
        class="textarea"
        @keydown.enter.prevent.exact="sendMessage"
        @keyup.prevent="checkNewLine"
        @keyup.ctrl.enter.prevent="newLine"
        @keyup.shift.enter.prevent="newLine"
        @keyup.enter.prevent="isEnterUp = true"
      />
      <FormImages
        ref="uploader"
        @files-changed="files = $event"
        @error="filesError = $event"
      />
    </div>
    <img
      src="@/assets/footprint.svg"
      alt="footprint"
      class="img-send"
      @click="imgClick"
    />
  </div>
</template>

<script>
import FormImages from '@/components/Chat/FormImages'
import BaseTextarea from '@/components/BaseTextarea'
import Spinner from '@/components/Spinner'
import checkButtons from '@/data/accessButons'
import { MESSAGES } from '@/store/types'

export default {
  components: { Spinner, BaseTextarea, FormImages },
  data: () => ({
    message: '',
    files: [],
    isEnterUp: true,
    isNewLine: true,
    filesError: false,
    checkButtons
  }),
  computed: {
    messageSending() {
      return this.$store.getters[MESSAGES.GETTERS.SENDING]
    },
    chatId() {
      return this.$store.getters[MESSAGES.GETTERS.CHAT].pk
    }
  },
  watch: {
    files() {
      this.$refs.messageTextBox.focus()
    }
  },
  methods: {
    async sendMessage() {
      if (this.isEnterUp) {
        this.isEnterUp = false
        const formIsValid = this.message.trim() && !this.filesError

        if (!formIsValid) return
        await this.$store.dispatch(MESSAGES.ACTIONS.SEND_MESSAGE, {
          chatId: this.chatId,
          text: this.cleanString(this.message),
          files: this.files
        })
        this.$refs.uploader.clear()
        this.message = ''
      }
    },
    newLine() {
      if (this.cleanString(this.message).length && this.isNewLine) {
        this.isNewLine = false
        this.message += '\n'
      }
    },
    cleanString(str) {
      return str.replace(/\s{2,}/g, ' ')
    },
    checkNewLine(e) {
      if (this.checkButtons(e.keyCode)) this.isNewLine = true
    },
    imgClick() {
      this.sendMessage()
      this.isEnterUp = true
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper-send-message {
  display: flex;
  align-items: flex-start;
}
.chat-form {
  width: 100%;
  background: #ffffff;
  position: relative;
  border: 2px solid #00aa8d;
  border-radius: 5px;
}
.img-send {
  cursor: pointer;
  margin-left: 10px;
}
</style>
