<template>
  <FinishedAt :date="date" class="finished-at">
    <img src="./images/timer.svg" class="icon" />
  </FinishedAt>
</template>

<script>
import FinishedAt from './FinishedAt'
export default {
  name: 'FinishedAtMobile',
  components: {
    FinishedAt
  },
  props: {
    date: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.icon {
  width: 17px;
  height: 20px;
  margin-right: 10px;
}
.finished-at {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71;
  color: #ffffff;
  display: none;
  width: 100%;
  padding: 8px;
  background: #00aa8d;
  position: absolute;
  left: 0;
  top: 80px;
  z-index: 2;
}
@media screen and (max-width: 768px) {
  .finished-at {
    display: flex;
  }
}
</style>
