<template>
  <div class="resume">
    <div class="header">
      <div class="title">{{ resume.title }}</div>
      <div class="label">finished</div>
    </div>
    <div class="description">
      {{ resume.text }}
    </div>
    <div class="images">
      <div
        v-for="(image, index) in resume.images"
        :key="image.image_pk"
        :style="{ backgroundImage: `url('${image.url}')` }"
        class="image"
        @click="showSlider(index)"
      />
    </div>
  </div>
</template>

<script>
import { CHAT_RESUME, CHAT_IMAGES_SLIDER } from '@/store/types'

export default {
  name: 'Resume',
  computed: {
    resume() {
      return this.$store.getters[CHAT_RESUME.GETTERS.FORM]
    }
  },
  methods: {
    showSlider(index) {
      this.$store.commit(
        CHAT_IMAGES_SLIDER.MUTATIONS.SET_IMAGES,
        this.resume.images.map((image) => image.url)
      )
      this.$store.commit(CHAT_IMAGES_SLIDER.MUTATIONS.SET_CURRENT_INDEX, index)
      this.$store.commit(
        CHAT_IMAGES_SLIDER.MUTATIONS.SET_SLIDER_VISIBILITY,
        true
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.resume {
  margin-bottom: 154px;
  margin-left: 20px;
  border-radius: 8px;
  box-shadow: 1px 2px 20px 0 rgba(0, 0, 0, 0.1);
  margin-right: 60px;
  padding: 30px 30px 20px;
}
.header {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.title {
  font-size: 24px;
  font-weight: 600;
  line-height: 0.83;
  color: #000000;
  word-break: break-word;
}
.label {
  padding: 5px 17px;
  border-radius: 11px;
  background-color: #00aa8d;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  color: #ffffff;
  height: 21px;
}
.description {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  color: #000000;
  margin-bottom: 20px;
  white-space: pre-wrap;
  word-break: break-word;
}
.images {
  display: flex;
  flex-wrap: wrap;
}
.image {
  margin-bottom: 10px;
  width: 112px;
  height: 112px;
  border-radius: 7px;
  margin-right: 15px;
  background-color: #fafafa;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}
</style>
