<template>
  <div class="form-images">
    <div v-if="validateError" class="error">
      {{ validateError }}
    </div>
    <transition name="fade">
      <div v-if="!validateError">
        <label for="uploader">
          <img src="@/assets/photo-camera.svg" />
        </label>
        <input
          id="uploader"
          ref="uploader"
          type="file"
          multiple
          accept="image/*"
          @change="imagesSelected"
        />
      </div>
    </transition>
    <transition name="fade">
      <div v-if="images.length" class="images">
        <div
          v-for="(image, index) in images"
          :key="index"
          class="image"
          :style="{ backgroundImage: `url('${image.url}')` }"
        >
          <img
            src="~@/assets/close.svg"
            class="close"
            @click="deleteImage(image.index)"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'FormImages',
  data: () => ({
    files: [],
    images: [],
    validateError: false
  }),
  watch: {
    files(files) {
      this.$emit(`files-changed`, files)
      this.validateError = this.validate(files)
      this.$emit(`error`, this.validateError)
    }
  },
  methods: {
    validate(files) {
      const fileSize = files.reduce((error, file) => {
        return error || file.size > 10000000
      }, false)

      if (fileSize) {
        return `Photo loading error. One of your files is bigger than 10 mb.`
      }

      if (files.length > 10) {
        return `The maximal amount of images is 10. Remove excess photos.`
      }

      return false
    },
    clear() {
      this.files = []
      this.images = []
    },
    deleteImage(index) {
      this.files.splice(index, 1)
      this.$set(
        this,
        `images`,
        this.images.filter((image) => image.index !== index)
      )
    },
    imagesSelected() {
      this.files = [...this.files, ...Array.from(this.$refs.uploader.files)]
      this.$refs.uploader.value = null
      this.drawImages()
    },
    drawImages() {
      let images = this.files
      if (!images.length) {
        this.images = []
        return
      }
      this.images = []

      images.map((image, index) => {
        const reader = new FileReader()
        reader.onload = () => {
          this.images.push({
            url: String(reader.result),
            index
          })
        }
        reader.readAsDataURL(image)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.form-images {
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
}
.error {
  background: #00aa8d;
  color: #fff;
  padding: 3px 7px;
  align-self: flex-start;
  border-radius: 10px;
  font-size: 10px;
}
label {
  margin-bottom: 10px;
}
.images {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
  min-height: 50px;
}
.image {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  background-position: center;
  background-size: cover;
  border-radius: 5px;
  position: relative;
  margin-bottom: 5px;
}
.close {
  cursor: pointer;
  position: absolute;
  top: 2px;
  right: 2px;
  padding: 3px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}
#uploader {
  display: none;
}

@media screen and (max-width: 860px) {
  .images {
    max-height: 110px;
    overflow: auto;
  }
}
</style>
