<template>
  <div class="base-header">
    <!--  -->
    <transition name="fade-modal">
      <Modal
        v-if="showExtendChatModal"
        @yes="extendChat"
        @no="showExtendChatModal = false"
      />
    </transition>
    <!--  -->
    <div class="header-wrapper">
      <div class="header-burger">
        <Burger />
        <slot />
      </div>
      <div class="actions">
        <Button
          v-if="$route.params.pk"
          class="pet-button"
          @click.native.stop="showPetDialog"
        >Pet</Button
        >
        <router-link to="/profile" class="header-avatar">
          <Tooltip class="settings" position="left"
                   v-if="chat.status !== `closed` && chat.status !== `completed` && chat.status !== `cancelled`">
            <template #tooltip-content>
              <TooltipLisItem
                @click="openEndChatModal"
              >End Chat</TooltipLisItem
              >
              <!--
              <TooltipLisItem
                v-if="chat.status !== `closed`"
                @click="openEndChatModal"
              >Cancel Chat</TooltipLisItem
              >-->
              <!--
              <TooltipLisItem
                v-if="![`active`, `closed`].includes(chat.status)"
                @click="showExtendChatModal = true"
              >Extend chat time</TooltipLisItem
              >
              <TooltipLisItem
                v-if="isActiveChat || resumeIsClear"
                @click="openResumeModalHandler"
              >Summary</TooltipLisItem
              >-->
            </template>
            <img
              v-if="$route.params.pk"
              src="@/assets/icons/settings.svg"
              alt="settings"
              tooltip="End Chat"
            />
          </Tooltip>
          <Avatar
            :image="userInfo.image_url"
            :full-name="userInfo.name"
            color="grey"
          />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import socket from '@/lib/network/socket'
import { mapGetters } from 'vuex'
import Avatar from '@/components/Avatar'
import Burger from '@/components/Menu/Burger.vue'
import Tooltip from '@/components/Tooltip.vue'
import Modal from '@/components/Modal.vue'
import TooltipLisItem from '@/components/TooltipLisItem'
import BaseButton from '@/components/BaseButton'
import { CHAT_COMPLETE, CHAT_RESUME, MESSAGES, PROFILE } from '../store/types'
import { PET_DIALOG } from '@/store/types'

export default {
  components: {
    TooltipLisItem,
    Avatar,
    Burger,
    Tooltip,
    Modal,
    Button: BaseButton
  },
  data: () => ({
    showExtendChatModal: false
  }),
  computed: {
    ...mapGetters({
      userInfo: PROFILE.GETTERS.INFO,
      chat: MESSAGES.GETTERS.CHAT
    }),
    resumeIsClear() {
      return this.$store.getters[CHAT_RESUME.GETTERS.RESUME_IS_CLEAR]
    },
    isActiveChat() {
      return this.chat.status === `active`
    }
  },
  methods: {
    showPetDialog() {
      this.$store.dispatch(
        PET_DIALOG.ACTIONS.OPEN_PASSPORT,
        this.chat.pet_id
      )
    },
    openResumeModalHandler() {
      if (this.isActiveChat) {
        this.openResumeModal()
      } else {
        this.openEndChatModal()
      }
    },
    openResumeModal() {
      // this.$store.commit(CHAT_RESUME.MUTATIONS.SET_MODAL_TYPE, `save`)
      // this.$store.commit(CHAT_RESUME.MUTATIONS.SET_FORM_VISIBILITY, true)
      this.$store.commit(CHAT_COMPLETE.MUTATIONS.SET_MODAL_TYPE, `save`)
      this.$store.commit(CHAT_COMPLETE.MUTATIONS.SET_FORM_VISIBILITY, true)
    },
    openEndChatModal() {
      // this.$store.commit(CHAT_RESUME.MUTATIONS.SET_MODAL_TYPE, `end`)
      // this.$store.commit(CHAT_RESUME.MUTATIONS.SET_FORM_VISIBILITY, true)
      this.$store.commit(CHAT_COMPLETE.MUTATIONS.SET_MODAL_TYPE, `save`)
      this.$store.commit(CHAT_COMPLETE.MUTATIONS.SET_FORM_VISIBILITY, true)
    },
    closeChat() {
      socket.send({ chat_close: { chat_pk: this.chat.pk } })
    },
    extendChat() {
      this.showExtendChatModal = false
      socket.send({
        prolongation_accept: {
          chat_pk: this.chat.pk,
          is_accepted: true
        }
      })
      this.$store.dispatch(MESSAGES.ACTIONS.LOAD_CHAT_PAGE)
    }
  }
}
</script>

<style lang="scss" scoped>
.base-header {
  background: #ffffff;
  transition: width 150ms;
  border-bottom: 1px solid #eee;
}

.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
  max-width: 930px;
  margin: 0 auto;
}

.header-avatar {
  height: 50px;
  width: 50px;
  position: relative;
}

.actions {
  display: flex;
  width: 165px;
  justify-content: flex-end;
}

.pet-button {
  width: 65px;
  margin-right: 55px;
  padding: 8px 14px;
}

.settings {
  position: absolute;
  top: 50%;
  left: -50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

.header-burger {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
}

.tooltip-text {
  font-size: 14px;
  font-weight: 600;
  color: #00aa8d;
  border-bottom: 1px solid #00aa8d;
  cursor: pointer;
}

.modal-body p {
  max-width: 155px;
  margin: auto;
}
// anim
.fade-modal-enter-active {
  animation: fade-in 0.3s ease;
}
.fade-modal-leave-active {
  animation: fade-out 0.3s ease;
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale(1.1);
  }
}
@media screen and (max-width: 1288px) {
  .header-wrapper {
    padding: 15px 34px;
  }
}
@media screen and (max-width: 768px) {
  .base-header {
    position: relative;
    min-height: 80px;
    padding: 0;
    border-bottom: 0;
    background: transparent !important;
  }
  .header-burger {
    width: calc(100% - 165px);
  }
  .header-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: 1px 0 16px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    padding: 15px 20px;
    z-index: 10;
  }
  .pet-button {
    margin-right: 50px;
  }
}
</style>
