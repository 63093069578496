<template>
  <Header>
    <router-link class="back-link" to="/">
      <img src="~@/assets/pages/arrow-left.svg" alt="arrow" class="arrow" />
      <HeaderTitle class="title-link">
        Settings
      </HeaderTitle>
    </router-link>
  </Header>
</template>

<script>
import HeaderTitle from '@/components/HeaderTitle'
import Header from '@/components/Header'
export default {
  name: 'ProfileHeader',
  components: {
    Header,
    HeaderTitle
  }
}
</script>

<style lang="scss" scoped>
.arrow {
  height: 24px;
}
.title-link {
  margin-left: 20px;
}
a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000;
}
.back-link {
  flex: 1;
}

@media screen and (max-width: 768px) {
  .arrow {
    display: none;
  }
  .title-link {
    margin-left: 30px;
  }
}
@media screen and (max-width: 480px) {
  .title-link {
    margin-left: 20px;
  }
}
</style>
