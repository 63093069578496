<template>
  <div class="pet-info">
    <div style="display: none">{{ passport }}</div>

    <div style="display: flex">
      <div class="passport">Pet's Name</div>
      <div class="passport" style="margin-left: auto; font-weight: bold">
        {{ passport.name ? passport.name : `-` }}
      </div>
    </div>

    <div style="display: flex">
      <div class="passport">KeepPet Passport ID</div>
      <div class="passport" style="margin-left: auto; font-weight: bold">
        {{ passport.passport_num ? passport.passport_num : `-` }}
      </div>
    </div>

    <div style="display: flex">
      <div class="passport">Species</div>
      <div class="passport" style="margin-left: auto; font-weight: bold">
        {{ passport.species ? passport.species.name : `-` }}
      </div>
    </div>
    <div style="display: flex">
      <div class="passport">Breed</div>
      <div class="passport" style="margin-left: auto; font-weight: bold">
        {{ passport.breed ? passport.breed.name : `-` }}
      </div>
    </div>
    <div style="display: flex">
      <div class="passport">Gender</div>
      <div class="passport" style="margin-left: auto; font-weight: bold">
        {{ passport.gender ? passport.gender : `-` }}
      </div>
    </div>
    <div style="display: flex">
      <div class="passport">Birthday</div>
      <div class="passport" style="margin-left: auto; font-weight: bold">
        {{ (age) ? age + ' / ' : '' }}
        {{ passport.birth_year ?? '????' }}-{{
          (passport.birth_month ?? '??').toString().padStart(2, '0')
        }}-{{ (passport.birth_day ?? '??').toString().padStart(2, '0') }}
      </div>
    </div>
    <div style="display: flex">
      <div class="passport">Color</div>
      <div class="passport" style="margin-left: auto; font-weight: bold">
        {{ color_info }}

      </div>
    </div>


    <div style="display: flex">
      <div class="passport">Serialized</div>
      <div class="passport" style="margin-left: auto; font-weight: bold">
        {{ passport.sterilized ? passport.sterilized : `-` }}
      </div>
    </div>

    <div style="display: flex">
      <div class="passport">Chip number</div>
      <div class="passport" style="margin-left: auto; font-weight: bold">
        {{ passport.chip_number ? passport.chip_number : `-` }}
      </div>
    </div>
    <!--
    <div class="row">
      <img src="./images/calendar.svg" class="icon"/>
      {{ passport.birth_year ?? '????' }}-{{
        passport.birth_month ?? '??'
      }}-{{ passport.birth_day ?? '??' }}
    </div>



    <div class="row">
      <img src="./images/microchip.svg" class="icon"/>
      {{ passport.chip_number ?? `-` }}
    </div>
    -->

    <div v-if="passport.info" class="description">
      {{ passport.info }}
    </div>

  </div>
</template>

<script>
import {PET_DIALOG} from '@/store/types'

export default {
  name: `PetInfo`,
  computed: {
    passport() {
      return this.$store.getters[PET_DIALOG.GETTERS.PASSPORT]
    },
    color_info() {
      let p = this.passport
      let color_info = "";
      if (p.color) {
        color_info = p.color.name
      }
      if (p.color_pattern) {
        color_info = !(color_info === "") ? (color_info + ", " + p.color_pattern.name) : p.color_pattern.name
      }
      if (color_info === "") {
        color_info = "-"
      }
      return color_info
    },
    age() {
      let age = 0
      if (!(this.passport.birth_year)) {
        return ""
      }

      if (!(this.passport.birth_month)) {
        return (new Date()).getFullYear() - this.passport.birth_year + "y"
      }
      let dtText = ""
      if (this.passport.birth_month) {
        dtText = this.passport.birth_year + "-" + this.passport.birth_month
      }
      if (this.passport.birth_day) {
        dtText += '-' + this.passport.birth_day
      } else {
        dtText += '-' + '1'
      }

      let dt = new Date(dtText)
      let currentDt = new Date()
      console.log(dtText, dt, new Date() - dt)
      let years = currentDt.getFullYear() - dt.getFullYear()
      let months = currentDt.getMonth() - dt.getMonth()
      if (months < 0) {
        years = years - 1
        months = months + 12
      }
      age = `${years}y ${months}m`
      return age
    },
    formattedBirthday() {
      return ""
      // return dayjs(this.passport.birthday).format(`DD.MM.YYYY`)
    }
  },
  methods: {
    monthDiff: function (d1, d2) {
      let months;
      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth();
      months += d2.getMonth();
      return months <= 0 ? 0 : months;
    }
  }
}
</script>

<style scoped>
.pet-info {
  width: 366px;
}

.row {
  display: flex;
  width: 216px;
  font-size: 14px;
  line-height: 1.86;
  color: #000000;
  align-items: center;
  margin-bottom: 22px;
  width: auto;

  &:last-child {
    margin-bottom: 0;
  }
}

.passport {
  font-size: 14px;
  line-height: 1.86;
  color: #000000;
  margin-top: 20px;
}

.icon {
  margin-right: 8px;
  width: 20px;
  height: auto;
}

.description {
  margin-top: 30px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.86;
  color: #000000;
  white-space: pre-wrap;
}

.contraindications-row {
  align-items: flex-start;
}

.contraindications {
  white-space: pre-wrap;
}
</style>
