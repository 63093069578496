<template>
  <SystemMessage
    v-if="is(`time_end`) && chat.status !== `active`"
    type="warning"
  >
    <div class="text">{{ message.text }}</div>
    <div v-if="isLast" class="buttons">
      <BaseButton
        v-if="![`active`, `closed`].includes(chat.status)"
        @click="extendChat"
        >Extend chat</BaseButton
      >
      <BaseButton
        v-if="false && canWriteSummary"
        hovered="true"
        @click="cancelExtendAndWriteResume"
        >Write summary</BaseButton
      >
    </div>
  </SystemMessage>

  <SystemMessage v-else-if="is(`chat_finished`)" type="success">
    <div class="text">{{ message.text }}</div>
    <div v-if="isLast" class="buttons">
      <BaseButton
        v-if="canWriteSummary && false"
        hovered="true"
        @click="cancelExtendAndWriteResume"
        >Write summary</BaseButton
      >
    </div>
  </SystemMessage>

  <SystemMessage v-else-if="is(`ask_increase_time`)" type="warning">
    <div class="text">{{ message.text }}</div>
    <div v-if="isLast" class="buttons">
      <BaseButton
        v-if="![`active`, `closed`].includes(chat.status)"
        @click="extendChat"
        >Extend chat</BaseButton
      >
      <BaseButton
        v-if="canWriteSummary"
        hovered="true"
        @click="cancelExtendAndWriteResume"
      >
        Cancel and write summary
      </BaseButton>
    </div>
  </SystemMessage>

  <SystemMessage v-else-if="is(`create_chat`)" type="warning">
    <div class="text">{{ message.text }}</div>
  </SystemMessage>

  <SystemMessage v-else-if="is(`reject_increase_time`)" type="error">
    <div class="text">{{ message.text }}</div>
  </SystemMessage>

  <SystemMessage v-else-if="is(`accept_increase_time`)" type="success">
    <div class="text">{{ message.text }}</div>
  </SystemMessage>

  <SystemMessage v-else-if="is(`chat_finished_by_mistake`)" type="error">
    <div class="text">{{ message.text }}</div>
  </SystemMessage>

  <SystemMessage v-else-if="is(`video_chat_start`)" type="success">
    <div class="text">{{ message.text }}</div>
  </SystemMessage>

  <SystemMessage v-else-if="is(`video_chat_end`)" type="warning">
    <div class="text">{{ message.text }}</div>
  </SystemMessage>
</template>

<script>
import SystemMessage from './SystemMessage'
import BaseButton from '@/components/BaseButton'
import socket from '@/lib/network/socket'
import { CHAT_RESUME, MESSAGES } from '@/store/types'

export default {
  name: `SystemMessages`,
  components: {
    SystemMessage,
    BaseButton
  },
  props: {
    message: {
      // example
      // chat: 690
      // creation_datetime: "2020-05-12T17:55:00.006Z"
      // images_urls: []
      // is_mine: false
      // is_read: false
      // is_read_by_me: true
      // msg_type: "time_end"
      // pk: 11811
      // text: "Chat time is over"
      // uuid: ""
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    canWriteSummary() {
      return this.chat.status !== `closed`
    },
    chat() {
      return this.$store.getters[MESSAGES.GETTERS.CHAT]
    },
    isLast() {
      const types = [
        `time_end`,
        `chat_finished`,
        `ask_increase_time`,
        `create_chat`,
        `reject_increase_time`,
        `accept_increase_time`,
        `chat_finished_by_mistake`
      ]
      const messages = this.$store.getters[MESSAGES.GETTERS.MESSAGES]
        .filter((message) => types.includes(message.msg_type))
        .map((message) => message.pk)

      return messages[messages.length - 1] === this.message.pk
    }
  },
  methods: {
    is(messageType) {
      return this.message.msg_type === messageType
    },
    // продлить чат
    extendChat() {
      socket.send({
        prolongation_accept: {
          chat_pk: this.message.chat,
          is_accepted: true
        }
      })
      this.$store.dispatch(MESSAGES.ACTIONS.LOAD_CHAT_PAGE)
    },
    // отменить продление и закрыть чат \ написать резюме
    cancelExtendAndWriteResume() {
      this.$store.commit(CHAT_RESUME.MUTATIONS.SET_MODAL_TYPE, `end`)
      this.$store.commit(CHAT_RESUME.MUTATIONS.SET_FORM_VISIBILITY, true)
    }
  }
}
</script>

<style lang="scss" scoped>
.base-button {
  padding: 8px 20px;
  white-space: nowrap;
  margin-left: 10px;
}
.buttons {
  margin-left: 20px;
  display: flex;
  align-items: center;
}
.text {
  white-space: nowrap;
  padding-top: 5px;
  padding-bottom: 5px;
}
.buttons {
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
}
.system-message, .system-message /deep/ .content {
  flex-wrap: wrap;
  max-width: 100%;
}
@media screen and (max-width: 600px) {
  .buttons {
    margin-left: 0;
  }
}
</style>
