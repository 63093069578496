<template>
  <div class="base-container">
    <VideoChatRequestsDialog
      v-for="message in videoChatRequests"
      :key="message.id"
      :message="message"
    />
    <PetDialogs />
    <AppNotifications />
    <SideBar />
    <div class="main" @click="some">
      <transition name="fade" mode="out-in">
        <div class="slot-wrap">
          <slot />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import SideBar from '@/components/Menu/SideBar.vue'
import AppNotifications from '@/components/common/AppNotifications'
import VideoChatRequestsDialog from '@/components/video-chats/VideoChatRequestDialog'
import PetDialogs from '@/components/pet-dialogs/PetDialogs.vue'
import { SIDEBAR_OPEN, VIDEO_CHATS } from '@/store/types'

export default {
  components: {
    PetDialogs,
    SideBar,
    AppNotifications,
    VideoChatRequestsDialog
  },
  computed: {
    isPanelOpen() {
      return this.$store.getters[SIDEBAR_OPEN.GETTERS.GET_IS_NAV_OPEN]
    },
    videoChatRequests() {
      return this.$store.getters[VIDEO_CHATS.GETTERS.REQUESTS]
    }
  },
  methods: {
    some(e) {
      if (
        !e.target.hasAttribute('data-burger') &&
        document.body.clientWidth < 769 &&
        this.isPanelOpen
      )
        this.toggleNav()
    },
    toggleNav() {
      this.$store.commit(SIDEBAR_OPEN.MUTATIONS.TOGGLE_NAV)
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/entry.scss';
.slot-wrap {
  position: relative;
}
</style>
