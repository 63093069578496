const accessButons = [
  96,
  97,
  98,
  99,
  110,
  100,
  101,
  102,
  103,
  104,
  105,
  107,
  109,
  106,
  111,
  226,
  90,
  88,
  67,
  86,
  66,
  78,
  77,
  188,
  190,
  191,
  222,
  186,
  76,
  75,
  74,
  72,
  71,
  70,
  68,
  83,
  65,
  220,
  221,
  219,
  80,
  79,
  73,
  85,
  89,
  84,
  82,
  69,
  87,
  81,
  187,
  189,
  48,
  57,
  56,
  55,
  54,
  53,
  52,
  51,
  50,
  49,
  192
]

export default (code) => {
  return accessButons.indexOf(code) !== -1
}
