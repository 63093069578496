import { CHAT_IMAGES_SLIDER } from '@/store/types'
import appConfig from '@/config'
const state = () => ({
  currentIndex: 0,
  images: [],
  showSlider: false
})

const getters = {
  [CHAT_IMAGES_SLIDER.GETTERS.SHOW_SLIDER]: (state) => state.showSlider,
  [CHAT_IMAGES_SLIDER.GETTERS.IMAGES]: (state) => state.images,
  [CHAT_IMAGES_SLIDER.GETTERS.CURRENT_IMAGE]: (state) =>
    appConfig.BACKEND_URL + state.images[state.currentIndex]
}

const mutations = {
  [CHAT_IMAGES_SLIDER.MUTATIONS.SET_SLIDER_VISIBILITY]: (state, status) => {
    state.showSlider = status
  },
  [CHAT_IMAGES_SLIDER.MUTATIONS.SET_IMAGES]: (state, list) => {
    state.images = [...list]
  },
  [CHAT_IMAGES_SLIDER.MUTATIONS.SET_CURRENT_INDEX]: (state, index) => {
    state.currentIndex = index
  },
  [CHAT_IMAGES_SLIDER.MUTATIONS.NEXT]: (state) => {
    state.currentIndex =
      state.currentIndex === state.images.length - 1
        ? 0
        : state.currentIndex + 1
  },
  [CHAT_IMAGES_SLIDER.MUTATIONS.PREV]: (state) => {
    state.currentIndex =
      state.currentIndex - 1 < 0
        ? state.images.length - 1
        : state.currentIndex - 1
  }
}

export default {
  state,
  getters,
  mutations
}
