<template>
  <div class="chat-container">
    <template v-if="showDateDivider(time)">
      <DateDivider :time="time" />
    </template>
    <div class="flex-container">
      <div class="wrap">
        <div class="avatar">
          <Avatar :image="avatar" :full-name="name" />
        </div>
        <div class="message">
          <div class="head-message">
            <span class="name">
              {{ name }}
            </span>
            <span class="time">
              {{ timeMessage }}
            </span>
            <img
              v-if="read && mine"
              class="check_message"
              src="@/assets/check_message.svg"
              alt="check_message"
            />
          </div>
          <div class="content">
            <p class="text">{{ text }} </p>
            <ImagesList v-if="images.length" :images="images" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/Avatar'
import DateDivider from '@/components/DateDivider'
import ImagesList from '@/components/Chat/ImagesList'
import { MESSAGES } from '@/store/types'

export default {
  components: { Avatar, DateDivider, ImagesList },
  props: {
    images: {
      type: Array,
      default: () => []
    },
    image_urls: {
      type: Array,
      default: () => []
    },
    pk: {
      type: Array,
      default: () => []
    },
    name: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    time: {
      type: String,
      default: ''
    },
    avatar: {
      type: String,
      default: ''
    },
    read: {
      type: Boolean,
      default: false
    },
    mine: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    messages() {
      return this.$store.getters[MESSAGES.GETTERS.MESSAGES]
    },
    timeMessage() {
      const date = new Date(this.time)
      const hours =
        date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
      const minutes =
        date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
      return `${hours}:${minutes}`
    }
  },
  methods: {
    showDateDivider(time) {
      let messageIdx = this.messages.findIndex(
        (e) => e.creation_datetime === time
      )
      if (messageIdx > 0) {
        let message = this.messages.find((e, idx) => idx === messageIdx - 1)
        return !(
          message.creation_datetime.slice(0, 10) === this.time.slice(0, 10)
        )
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-container {
  margin-bottom: 30px;
}
.flex-container {
  display: flex;
  justify-content: flex-start;
}
.wrap {
  display: flex;
  width: 100%;
}
.message {
  display: flex;
  flex-direction: column;
  width: calc(100% - 60px);
}
.head-message {
  display: flex;
}
.wrap .avatar {
  height: 44px;
  width: 44px;
  margin-right: 15px;
}
.wrap .name {
  font-weight: bold;
  color: #000000;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(100% - 40px);
}
.wrap .time {
  margin-left: 10px;
  font-size: 12px;
  color: #939393;
}
.wrap .check_message {
  margin-left: 10px;
}
.wrap .text {
  margin: 10px 0 0 0;
  font-family: inherit;
  white-space: pre-wrap;
}
</style>
