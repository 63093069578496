<template>
  <div v-if="videoChats.length" class="video-chats">
    <template v-if="hasAccessToDevices">
      <VideoChat v-for="chat in videoChats" :key="chat.id" :chat="chat" />
    </template>
    <AccessToDevicesDialog v-else />
  </div>
</template>

<script>
import VideoChat from './VideoChat'
import { VIDEO_CHATS } from '@/store/types'
import AccessToDevicesDialog from './AccessToDevicesDialog'
import { devicesIsAvailable } from '@/lib/video-chats/video-call'

export default {
  components: {
    VideoChat,
    AccessToDevicesDialog
  },
  data: () => ({
    accessDevicesTimeout: null,
    hasAccessToDevices: true
  }),
  watch: {
    'videoChats.length'(devices) {
      devices > 0 && this.checkDevices()
    }
  },
  computed: {
    videoChats() {
      return this.$store.getters[VIDEO_CHATS.GETTERS.CHATS]
    }
  },
  methods: {
    async checkDevices() {
      try {
        this.hasAccessToDevices = await devicesIsAvailable()
      } catch (e) {
        this.hasAccessToDevices = false
        console.error(`failed to check devices`, e)
      }
    }
  }
}
</script>

<style scoped>
.video-chats {
  position: fixed;
  height: 100vh;
  width: 0;
  z-index: 1000;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 20px;
  padding-top: 0;
}
</style>
