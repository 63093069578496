import config from '@/config'
import axios from 'axios'
const proxy = `https://cors-anywhere.herokuapp.com/`

const get = (url) => axios.get(proxy + url)

export const getPlacesList = (query) =>
  get(
    `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${query}&key=${config.GOOGLE_KEY}`
  )

export const getPlace = (placeId) =>
  get(
    `https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&fields=formatted_address,geometry&key=${config.GOOGLE_KEY}`
  )
