<template>
  <Dialog class="dialog" @closeModal="close">
    <Header @close="close" />
    <div class="content">
      <Images />
      <Info />
    </div>
  </Dialog>
</template>

<script>
import UIModal from '@/components/UI/UIModal'
import PetDialogHeader from './content/PetDialogHeader'
import PetInfo from './content/PetInfo'
import PetImagesList from './content/PetImagesList'
import { PET_DIALOG } from '@/store/types'
export default {
  name: `PetDialog`,
  components: {
    Dialog: UIModal,
    Header: PetDialogHeader,
    Info: PetInfo,
    Images: PetImagesList
  },
  methods: {
    close() {
      this.$store.commit(
        PET_DIALOG.MUTATIONS.SET_PASSPORT_DIALOG_VISIBILITY,
        false
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog /deep/ .modal-container {
  width: 100%;
  max-width: 700px;
  text-align: unset;
}
.content {
  display: flex;
}
</style>
