<template>
  <LoginPage class="login-page">
    <Card class="help-card">
      <form
        id="login"
        action="#"
        method="post"
        class="login-card_form"
        @submit.prevent="checkForm"
      >
        <div class="login-card_info">
          <div class="login-card_body">
            <BaseInput
              v-model="form.login"
              placeholder="Enter your Login"
              type="text"
              label="Login"
              :error="authError"
              @focus="clearError()"
            />
            <BaseInput
              v-model="form.password"
              placeholder="Enter your Password"
              type="password"
              label="Password"
              :error="authError"
              @focus="clearError()"
            />
            <transition name="fade">
              <p v-if="authError" class="error">{{ authError }}</p>
              <p v-if="isInvalidForm" class="error">Wrong Email or Phone</p>
            </transition>
          </div>
          <div class="login-card_actions">
            <router-link to="/forgot-password" class="base-link">
              Forgot Password?
            </router-link>
            <BaseButton
              type="submit"
              class="base-button"
              :disabled="isInvalidForm"
            >
              Log In
            </BaseButton>
          </div>
        </div>
      </form>
    </Card>
  </LoginPage>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseButton from '@/components/BaseButton'
import BaseInput from '@/components/BaseInput'
import Card from '@/components/Card'
import LoginPage from '@/components/Login/LoginPage'
import { AUTH } from '@/store/types'
export default {
  components: { LoginPage, BaseButton, BaseInput, Card },
  data() {
    return {
      form: {
        login: '',
        email: '',
        phone: '',
        password: ''
      },
      isInvalidForm: false
    }
  },
  computed: {
    ...mapGetters({
      authError: AUTH.GETTERS.GET_AUTH_ERROR
    })
  },
  created() {
    this.$store.commit(AUTH.MUTATIONS.AUTH_ERROR, '')
  },
  methods: {
    validateFields() {
      let isInvalid = true
      if (
        this.form.login
          .trim()
          .match(/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/)
      ) {
        this.form.phone = this.form.login
        return (isInvalid = false)
      }
      if (
        this.form.login
          .trim()
          .match(
            "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
          )
      ) {
        this.form.email = this.form.login
        return (isInvalid = false)
      }
      this.isInvalidForm = isInvalid
      return isInvalid
    },
    checkForm() {
      if (this.validateFields()) return
      const form = {
        password: this.form.password
      }
      !this.form.email
        ? (form.phone = this.form.phone.trim())
        : (form.email = this.form.email.trim())
      this.$store.dispatch(AUTH.ACTIONS.LOGIN, form)
    },
    clearError() {
      this.isInvalidForm = false
      this.form.email = ''
      this.form.phone = ''
      this.$store.commit(AUTH.MUTATIONS.AUTH_ERROR, '')
    }
  }
}
</script>

<style lang="scss" scoped>
.base-link {
  color: #00aa8d;
}

.login-card_form {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  background: rgba(0, 170, 141, 0.1);
}

.login-card_info {
  padding: 30px 20px;

  .title {
    font-size: 24px;
    color: #00aa8d;
    font-weight: 600;
    text-align: center;
    margin-top: 0;
  }

  .login-card_actions {
    text-align: center;

    .base-button {
      margin-top: 20px;
    }
  }
}

.error {
  color: #b83625;
  text-align: center;
  margin-bottom: 10px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

@media (max-width: 480px) and (max-height: 470px) {
  .login-page {
    display: block;
  }
  .help-card {
    margin: 0 auto;
  }
}
@media (min-width: 480px) and (max-height: 480px) {
  .login-page {
    display: block;
  }
  .help-card {
    margin: 0 auto;
  }
}
@media screen and (max-width: 480px) {
  .login-card_info {
    padding: 20px;
  }
}
</style>
