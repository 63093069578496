import {
  CHAT_LIST,
  CHAT_RESUME,
  COMMON,
  MESSAGES,
  PROFILE
} from '@/store/types'
import network from '@/lib/network'
import socket from '@/lib/network/socket'
import uuid from 'uuid/v5'
import router from '@/router'
import {clearResumeForm} from '@/store/chatResume'
import {videoChats} from '@/lib/video-chats/video-call'

const clearChatGenerator = () => ({
  owner: {},
  resume: {
    title: '',
    text: '',
    images_urls: []
  }
})

const state = () => ({
  messages: [],
  currentChat: clearChatGenerator(),
  next: null,
  nextFetching: false,
  messageSending: false
})

const getters = {
  [MESSAGES.GETTERS.MESSAGES]: (state, getters) => {
    const userInfo = getters[PROFILE.GETTERS.INFO]
    const chat = getters[MESSAGES.GETTERS.CHAT]
    return state.messages.map((message) => {
      return {
        ...message,
        image: message.is_mine ? userInfo.image_url : chat.owner.image_url,
        name: message.is_mine ? userInfo.name : chat.owner.name
      }
    })
  },
  [MESSAGES.GETTERS.CHAT]: (state) => state.currentChat,
  [MESSAGES.GETTERS.NEXT_LIST_URL]: (state) => state.next,
  [MESSAGES.GETTERS.NEXT_FETCHING]: (state) => state.nextFetching,
  [MESSAGES.GETTERS.SENDING]: (state) => state.messageSending
}

const mutations = {
  [MESSAGES.MUTATIONS.SET_MESSAGES]: (state, messages) => {
    state.messages = messages.reverse()
  },
  [MESSAGES.MUTATIONS.ADD_MESSAGES]: (state, messages) => {
    state.messages = [...messages.reverse(), ...state.messages]
  },
  [MESSAGES.MUTATIONS.CLEAR_CURRENT_CHAT]: (state) => {
    state.currentChat = clearChatGenerator()
  },
  [MESSAGES.MUTATIONS.SET_CURRENT_CHAT]: (state, chat) => {
    console.warn('current_chat', state, chat)
    state.currentChat = chat
  },
  [MESSAGES.MUTATIONS.SET_NEXT_LIST_URL]: (state, ulr) => {
    state.next = ulr
  },
  [MESSAGES.MUTATIONS.SET_NEXT_FETCHING]: (state, status) => {
    state.nextFetching = status
  },
  [MESSAGES.MUTATIONS.ADD_MESSAGE]: (state, message) => {
    state.messages.push(message)
  },
  [MESSAGES.MUTATIONS.READ_MESSAGE]: (state, messageId) => {
    const message = state.messages.find((message) => message.pk === messageId)
    if (message) {
      message.is_read = true
    }
  },
  [MESSAGES.MUTATIONS.CLOSE_CHAT]: (state) => {
    state.currentChat.status = `closed`
  },
  [MESSAGES.MUTATIONS.SET_SENDING]: (state, status) => {
    state.messageSending = status
  },
  [MESSAGES.MUTATIONS.TOGGLE_CURRENT_CHAT_TYPE]: (state, status) => {
    state.currentChat.status = status
  }
}

const actions = {
  async [MESSAGES.ACTIONS.LOAD_CHAT_PAGE]({dispatch, commit}) {
    const chatId = router.currentRoute.params.pk
    commit(MESSAGES.MUTATIONS.SET_NEXT_FETCHING, false)
    commit(MESSAGES.MUTATIONS.SET_NEXT_LIST_URL, null)
    commit(MESSAGES.MUTATIONS.SET_MESSAGES, [])
    commit(MESSAGES.MUTATIONS.CLEAR_CURRENT_CHAT)
    await dispatch(MESSAGES.ACTIONS.LOAD_CURRENT_CHAT, chatId)
    await dispatch(MESSAGES.ACTIONS.LOAD_MESSAGES, chatId)
  },
  [MESSAGES.ACTIONS.READ_CURRENT_MESSAGES]({dispatch, getters}) {
    const messages = getters[MESSAGES.GETTERS.MESSAGES]
    dispatch(MESSAGES.ACTIONS.READ_MESSAGES, messages)
  },
  // уведомления о прочтении сообщений клиента
  async [MESSAGES.ACTIONS.READ_MESSAGES]({commit, getters}, messages) {
    if (!getters[COMMON.GETTERS.WINDOW_IN_FOCUS]) {
      return
    }
    try {
      const ids = []
      messages.forEach((message) => {
        if (!message.is_read && !message.is_mine) {
          ids.push(message.pk)
          commit(CHAT_LIST.MUTATIONS.DECREMENT_CHAT_LIST_COUNTERS, message.chat)
        }
      })
      if (ids.length) {
        socket.send({
          message_read_notification: {messages_pks: ids}
        })
      }
    } catch (e) {
      console.log(e)
    }
  },
  async [MESSAGES.ACTIONS.LOAD_NEXT]({commit, getters, dispatch}) {
    const nextFetching = getters[MESSAGES.GETTERS.NEXT_FETCHING]
    if (nextFetching) {
      return
    }
    try {
      commit(MESSAGES.MUTATIONS.SET_NEXT_FETCHING, true)
      let nextPage = getters[MESSAGES.GETTERS.NEXT_LIST_URL]

      if (window.location.protocol === `https:`) {
        nextPage = nextPage.replace(/http/, `https`)
      }

      const {messages, next} = await network.getNextMessages(nextPage)
      commit(MESSAGES.MUTATIONS.ADD_MESSAGES, messages)
      commit(MESSAGES.MUTATIONS.SET_NEXT_LIST_URL, next)
      dispatch(MESSAGES.ACTIONS.READ_MESSAGES, messages)
    } catch (e) {
      console.log(e)
    } finally {
      commit(MESSAGES.MUTATIONS.SET_NEXT_FETCHING, false)
    }
  },
  async [MESSAGES.ACTIONS.LOAD_CURRENT_CHAT]({commit}, chatId) {
    try {
      let chat = await network.getChatInfo(chatId)
      console.log('chats res', chat)
      if (chat.pk === chatId) {
        commit(MESSAGES.MUTATIONS.SET_CURRENT_CHAT, chat)
        commit(CHAT_LIST.MUTATIONS.REPLACE_CHAT, chat)
        commit(
          CHAT_RESUME.MUTATIONS.SET_FORM,
          chat.resume ? chat.resume : {...clearResumeForm}
        )

        const videoChat = videoChats.list[chat.pk]
        if (videoChat && chat.status !== `active`) {
          videoChat.close()
        }
      }
    } catch (e) {
      console.log(e)
    }
  },
  async [MESSAGES.ACTIONS.LOAD_MESSAGES]({commit, dispatch}, chatId) {
    try {
      let res = await network.getChatMessages(chatId)
      const messages = res.res.messages
      console.log('Messages', messages)
      const next = null
      // const { messages, next } = await network.getChatMessages(chatId)
      if (router.currentRoute.params.pk === chatId) {
        commit(MESSAGES.MUTATIONS.SET_NEXT_LIST_URL, next)
        commit(MESSAGES.MUTATIONS.SET_MESSAGES, messages)
        dispatch(MESSAGES.ACTIONS.READ_MESSAGES, messages)
      }
    } catch (e) {
      console.log(e)
    }
  },
  [MESSAGES.ACTIONS.CLOSE_CURRENT_CHAT]({getters}) {
    const chat = getters[MESSAGES.GETTERS.CHAT]
    socket.send({chat_close: {chat: chat.pk}})
  },
  [MESSAGES.ACTIONS.CANCEL_CURRENT_CHAT]({getters}) {
    const chat = getters[MESSAGES.GETTERS.CHAT]
    socket.send({chat_close: {chat: chat.pk, status: 'cancelled'}})
  },
  [MESSAGES.ACTIONS.CLOSE_CHAT](_, id) {
    socket.send({chat_close: {chat: id}})
  },
  async [MESSAGES.ACTIONS.SEND_MESSAGE](
    {commit, getters},
    {chatId, text, files}
  ) {
    if (getters[MESSAGES.GETTERS.SENDING]) {
      return
    }
    commit(MESSAGES.MUTATIONS.SET_SENDING, true)
    try {
      const splitMessage = text.match(/(.{1,1000})/gims) || [text]

      const NAMESPACE = '1b671a64-40d5-491e-99b0-da01ff1f3341'
      const messages = splitMessage.map((text) => ({
        chat: chatId,
        text,
        uuid: uuid(text, NAMESPACE)
      }))

      if (files.length) {
        const uploads = await Promise.all(
          files.map((file) => {
            const formData = new FormData()
            formData.append(`image_file`, file)
            return network.uploadPhotos(chatId, formData)
          })
        )

        messages[0].image_ids = uploads.map((upload) => upload.res.image_id)
      }

      messages.forEach((message) => {
        socket.send({
          message
        })
      })
    } catch (e) {
      console.log(e)
    } finally {
      commit(MESSAGES.MUTATIONS.SET_SENDING, false)
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
