<template>
  <div class="images-list">
    <div
      v-for="(image, index) in images"
      :key="image"
      :style="{ backgroundImage: `url('${appConfig.BACKEND_URL}${image}')` }"
      class="image"
      @click="showSlider(index)"
    />
  </div>
</template>

<script>
import { CHAT_IMAGES_SLIDER } from '@/store/types'
import appConfig from "@/config";
export default {
  name: 'ImagesList',
  computed: {
    appConfig() {
      return appConfig
    }
  },
  props: {
    images: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    showSlider(index) {
      this.$store.commit(CHAT_IMAGES_SLIDER.MUTATIONS.SET_IMAGES, this.images)
      this.$store.commit(CHAT_IMAGES_SLIDER.MUTATIONS.SET_CURRENT_INDEX, index)
      this.$store.commit(
        CHAT_IMAGES_SLIDER.MUTATIONS.SET_SLIDER_VISIBILITY,
        true
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.images-list {
  display: flex;
  flex-wrap: wrap;
}
.image {
  background-color: #eee;
  margin-top: 10px;
  width: 200px;
  height: 200px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 7px;
  margin-right: 10px;
  &:hover {
    cursor: pointer;
  }
}
@media screen and (max-width: 835px) {
  .image {
    width: calc(50% - 10px);
    height: 170px;
  }
}
@media screen and (max-width: 768px) {
  .image {
    width: 200px;
    height: 200px;
  }
}
@media screen and (max-width: 550px) {
  .image {
    width: calc(50% - 10px);
    height: 170px;
  }
}
@media screen and (max-width: 450px) {
  .image {
    height: 150px;
  }
}
@media screen and (max-width: 420px) {
  .image {
    height: 130px;
  }
}
@media screen and (max-width: 380px) {
  .image {
    width: 200px;
    height: 200px;
  }
}
</style>
