import network from '../network'
import config from '@/config'
import AgoraRTC from 'agora-rtc-sdk'
import store from '@/store'
import { VIDEO_CHATS } from '@/store/types'

const handleFail = console.error

const removeVideoStream = (stream) => {
  stream && stream.stop && stream.stop()
}

export const devicesIsAvailable = () => navigator.mediaDevices.getUserMedia(
    {video: true, audio: true}
  )


export const videoChats = {
  idsList: [],
  list: {}, // {id, localStream, remoteStream}
  closeAll() {
    Object.keys(videoChats.list).forEach((chatId) =>
      videoChats.list[chatId].close()
    )
  }
}

const create = async (chatId) => {
  const credentials = await network.getVideoChatCredentials(chatId)

  let muted = false

  const clientConfig = {
    mode: `live`,
    codec: `h264`
  }

  const client = AgoraRTC.createClient(clientConfig)

  const streams = {
    localStream: null,
    remoteStream: null
  }

  const updateSound = () => {
    if (streams.localStream) {
      if (muted) {
        streams.localStream.muteAudio()
      } else {
        streams.localStream.unmuteAudio()
      }
    }
  }

  const statuses = {
    join: false
  }

  const playRemote = () => {
    if (
      document.getElementById(`${chatId}-remote-stream`) &&
      streams.remoteStream
    ) {
      streams.remoteStream.play(`${chatId}-remote-stream`, { fit: 'contain' })
    }
  }

  return {
    id: chatId,
    client,
    streams,
    credentials,
    playRemote,
    isMuted() {
      return muted
    },
    toggleSound() {
      muted = !muted
      updateSound()
    },
    async close() {
      network.closeVideoChat(chatId)
      store.commit(VIDEO_CHATS.MUTATIONS.REMOVE_CHAT, chatId)
      client.leave(console.log, console.error)
      client.off(`stream-subscribed`)
      if (streams.localStream) {
        client.unpublish(streams.localStream)
        removeVideoStream(streams.localStream)
        if (streams.localStream.close) {
          streams.localStream.close()
        }
      }
      if (streams.remoteStream) {
        client.unsubscribe(streams.remoteStream)
        removeVideoStream(streams.remoteStream)
      }
      videoChats.idsList = videoChats.idsList.filter((id) => chatId !== id)
      delete videoChats.list[chatId]
    },
    join() {
      if (statuses.join) {
        return
      }
      const join = () => {
        statuses.join = true
        client.join(
          credentials.token,
          credentials.channel_name,
          credentials.user_id,

          // create streams
          () => {
            streams.localStream = AgoraRTC.createStream({
              streamID: chatId,
              audio: true,
              video: true,
              screen: false
            })

            streams.localStream.init(() => {
              streams.localStream.play(`${chatId}-local-stream`, { fit: `cover` })
              client.publish(streams.localStream, handleFail)
            })
          },
          console.error
        )
      }

      client.on(`stream-added`, (e) => {
        client.subscribe(e.stream, handleFail)
        updateSound()
      })
      client.on(
        `stream-subscribed`,
        (e) => {
          streams.remoteStream = e.stream
          playRemote()
        },
        console.error
      )
      client.init(config.AGORA_ID, join, console.error)
      client.on(`stream-removed`, () => videoChats.list[chatId].close())
      client.on(`error`, console.error)
    }
  }
}

export const createVideoChat = async (chatId) => {
  videoChats.idsList.push(chatId)
  videoChats.list[chatId] = await create(chatId)
  store.commit(VIDEO_CHATS.MUTATIONS.ADD_CHAT, {
    id: chatId,
    endDate: videoChats.list[chatId].credentials.finished_at
  })
}
