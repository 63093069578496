<template>
  <div class="resume-modal" @mousedown="hideModal">
    <div class="dialog-wrapper">
      <div class="dialog" @mousedown.stop="">
        <Spinner v-if="loading"/>
        <div class="header">
          End Chat
          <img src="./images/close.svg" class="close" @click="hideModal"/>
        </div>
        <!--
        <div style="font-size: 16px">
          If you helped the customer and answered questions, then click the "complete chat".
        </div>
        <div style="font-size: 16px">
          If it wasn't possible to talk with the customer, or you were not asked any questions, or
          the chat was started by mistake, then click the "cancel chat".
        </div>
        -->
        <ul style="padding-left: 10px">
          <li>If you helped the customer and answered questions, then click the "complete chat".
          </li>
          <li>If it wasn't possible to talk with the customer, or you were not asked any questions,
            or
            the chat was started by mistake, then click the "cancel chat".
          </li>
        </ul>
        <div class="buttons">
          <BaseButton class="resume-button mistake" @click="closeByMistake"
          >Cancel Chat
          </BaseButton
          >
          <div style="width: 20px"></div>
          <BaseButton class="resume-button success" @click="closeBySuccess"
          >Complete Chat
          </BaseButton>


        </div>
      </div>


    </div>
  </div>
</template>

<script>

import BaseButton from '@/components/BaseButton'
import Spinner from '@/components/Spinner.vue'
import {CHAT_COMPLETE} from '@/store/types'
import {validate, hasErrors} from '@/lib/helpers/validate'

export default {
  name: 'CompleteModal',
  components: {

    BaseButton,
    Spinner
  },
  data: () => ({
    form: {
      title: ``,
      text: ``,
      images: []
    },
    errors: {},
    formValidators: {
      title: [
        (v) => !(v && v.trim()) && `This field is required`,
        (v) => v.length > 100 && `Maximum length 100 characters`
      ],
      text: [(v) => !(v && v.trim()) && `This field is required`]
    }
  }),
  computed: {
    loading() {
      return this.$store.getters[CHAT_COMPLETE.GETTERS.SAVING]
    },
    saveButtonText() {
      return this.$store.getters[CHAT_COMPLETE.GETTERS.MODAL_TYPE] === `end`
        ? `Send`
        : `Save`
    }
  },
  mounted() {
    // закрывать окно при помощи кнопки 'esc'
    document.addEventListener('keyup', this.keyboardEscHandler)
  },
  destroyed() {
    // закрывать окно при помощи кнопки 'esc'
    document.removeEventListener('keyup', this.keyboardEscHandler)
  },
  created() {
    this.form = {...this.$store.getters[CHAT_COMPLETE.GETTERS.FORM]}
  },
  methods: {
    validate,
    hasErrors,
    checkForm() {
      this.$set(this, `errors`, validate(this.form, this.formValidators))
    },
    keyboardEscHandler(e) {
      if (e.keyCode === 27) {
        this.hideModal()
      }
    },
    hideModal() {
      this.$store.commit(CHAT_COMPLETE.MUTATIONS.SET_FORM_VISIBILITY, false)
    },
    save() {
      this.checkForm()
      if (this.hasErrors(this.errors)) {
        return
      }

      this.$store.commit(CHAT_COMPLETE.MUTATIONS.SET_FORM, this.form)
      this.$store.dispatch(CHAT_COMPLETE.ACTIONS.SAVE_RESUME)
    },
    closeByMistake() {
      this.$store.dispatch(CHAT_COMPLETE.ACTIONS.CLOSE_BY_MISTAKE)
    },
    closeBySuccess() {
      this.$store.dispatch(CHAT_COMPLETE.ACTIONS.CLOSE_BY_COMPLETE)
    }
  }
}
</script>

<style lang="scss" scoped>
.resume-modal {
  z-index: 300;
  left: 0;
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.2);
}

.dialog-wrapper {
  width: 100%;
  height: auto;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.error {
  color: darkred;
  margin-top: 3px;
  font-size: 12px;
}

.dialog {
  width: 100%;
  max-width: 580px;
  border-radius: 8px;
  box-shadow: 1px 2px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 30px 20px;
  padding-left: 40px;
  position: relative;
}

.close {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  cursor: pointer;
}

.header {
  position: relative;
  font-size: 24px;
  font-weight: 600;
  line-height: 0.83;
  color: #000000;
  margin-bottom: 10px;
}

.title {
  margin-bottom: 0;
}

.resume-input {
  & /deep/ {
    .base-input_input {
      border: 1px solid #e3e3e3;
      font-weight: normal;
    }

    .base-textarea_textarea {
      border: 1px solid #e3e3e3;
      border-radius: 5px;
      min-height: 120px;
      font-size: 14px;
      color: #000;
    }
  }
}

.text {
  margin-top: 20px;
}

.images-container {
  border-radius: 5px;
  border: solid 1px #c4c4c4;
  border-style: dashed;
  margin-top: 20px;
  padding: 20px;
  height: auto;
  min-height: 170px;
}

.buttons {
  display: flex;
  justify-content: center;
  padding-top: 20px;

  .resume-button {
    width: auto;
    font-weight: 600;
  }

  .success {
    width: 230px;
    background: transparent;
    color: #00aa8d;
    border-color: #00aa8d;
  }

  .success:hover {
    background: #00aa8d;
    color: white;
    border-color: #00aa8d;
  }

  .mistake {
    width: 230px;
    background: transparent;
    color: #ee5245;
    border-color: #ee5245;
  }

  .mistake:hover {
    background: #ee5245;
    color: white;
    border-color: #ee5245;
  }

  .save {
    margin-left: 20px;
    width: 102px;
  }
}
</style>
