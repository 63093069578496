<template>
  <div class="chat-container">
    <div class="flex-container">
      <div class="wrap">
        <div class="avatar">
          <Avatar :image="avatar" :full-name="name" />
        </div>
        <div class="message">
          <p class="from">
            {{ from }}
          </p>
          <p class="name">
            {{ name }}
          </p>
        </div>
      </div>
      <p class="date-request" />
      <div class="actions">
        <BaseButton
          v-if="showRedirectButton"
          hovered="false"
          class="base-button accept"
          @click="openChat"
        >
          Open
        </BaseButton>
        <p v-if="accepted && !showRedirectButton">
          You accepted the chat. Waiting for user response.
        </p>
        <BaseButton
          v-if="showRedirectButton || showActionsButtons"
          hovered="true"
          class="pet-button"
          @click="openPassport"
        >
          Pet
        </BaseButton>
        <BaseButton
          v-if="showActionsButtons"
          :inactive="isReconnecting || !!hasAcceptedChat"
          error="true"
          class="base-button not-accept"
          @click="doNotAcceptTicket"
        >
          Do Not Accept
        </BaseButton>
        <BaseButton
          v-if="showActionsButtons"
          :inactive="isReconnecting || !!hasAcceptedChat"
          hovered="false"
          class="base-button accept"
          @click="acceptTicket"
        >
          Accept
        </BaseButton>
      </div>
    </div>
    <hr />
  </div>
</template>

<script>
import Avatar from '@/components/Avatar.vue'
import BaseButton from '@/components/BaseButton'
import { CHAT_REQUESTS, COMMON, PET_DIALOG } from '@/store/types'

export default {
  components: { Avatar, BaseButton },
  props: {
    accepted: {
      type: Boolean,
      default: false
    },
    passport: {
      type: [String, Number, null],
      default: ``
    },
    name: {
      type: String,
      default: ''
    },
    from: {
      type: String,
      default: ''
    },
    avatar: {
      type: String,
      default: ''
    },
    id: {
      type: Number,
      default: null
    },
    pk: {
      type: String,
      default: null
    },
    chat: {
      type: [Number, String, null],
      default: null
    },
    pet_id: {
      type: [Number, String, null],
      default: null
    },
    finished: {
      type: Number,
      default: null
    },
    showRedirectButton: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    currentTime: null,
    timer: null
  }),
  computed: {
    showActionsButtons() {
      return !this.accepted && !this.showRedirectButton
    },
    hasAcceptedChat() {
      return this.$store.getters[CHAT_REQUESTS.GETTERS.HAS_ACCEPTED]
    },
    timeRemaining() {
      if (this.currentTime > 59) return '01:00'
      return this.currentTime < 10
        ? '00:0' + this.currentTime
        : '00:' + this.currentTime
    },
    isReconnecting() {
      return this.$store.getters[COMMON.GETTERS.RECONNECTING]
    }
  },
  watch: {
    currentTime() {
      if (this.currentTime === 0) this.stopTimer()
    }
  },
  mounted() {
    this.currentTime = Math.floor(this.finished)
    this.startTimer()
  },
  destroyed() {
    this.stopTimer()
  },
  methods: {
    openPassport() {
      this.$store.dispatch(PET_DIALOG.ACTIONS.OPEN_PASSPORT, this.pet_id)
    },
    startTimer() {
      this.timer = setInterval(() => this.currentTime--, 1000)
    },
    stopTimer() {
      clearTimeout(this.timer)
    },
    acceptTicket() {
      this.$store.dispatch(CHAT_REQUESTS.ACTIONS.ACCEPT_REQUEST, this.pk)


    },
    doNotAcceptTicket() {
      this.$store.dispatch(
        CHAT_REQUESTS.ACTIONS.DO_NOT_ACCEPT_REQUESTS,
        this.id
      )
    },
    openChat() {
      this.$router.push({
        name: `Chat`,
        params: {
          pk: this.chat
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-container {
  max-width: 930px;
  margin: 0 auto;
}
.flex-container {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
}
// name, avatar
.wrap {
  display: flex;
  align-items: center;
}
.avatar {
  height: 50px;
  width: 50px;
  margin-right: 15px;
}
.message {
  width: 190px;
}
.pet-button {
  width: 81px;
  margin-left: 20px;
}
.from {
  font-size: 12px;
  line-height: 2.17;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.name {
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.date-request {
  display: flex;
  align-items: center;
  opacity: 0.6;
  font-size: 12px;
  line-height: 1;
  color: #1e272e;
  margin-left: 10px;
}
// buttons
.actions {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.not-accept {
  width: 159px;
  margin-left: 20px;
}
.accept {
  width: 120px;
  margin-left: 20px;
}
hr {
  background: #ebebeb;
  border: none;
  height: 2px;
  margin: 15px 0;
}
@media screen and (max-width: 1288px) {
  .chat-container {
    padding: 0 34px;
  }
  .flex-container {
    padding-left: 0;
  }
}
@media screen and (max-width: 1030px) {
  .message {
    width: 170px;
  }
}
@media screen and (max-width: 1000px) {
  .not-accept {
    width: 139px;
  }
  .accept {
    width: 100px;
  }
}
@media screen and (max-width: 930px) {
  .message {
    width: 120px;
  }
}
@media screen and (max-width: 880px) {
  .message {
    width: 100px;
  }
}
@media screen and (max-width: 860px) {
  .not-accept {
    width: 120px;
  }
  .accept {
    width: 70px;
  }
}
@media screen and (max-width: 810px) {
  .chat-container {
    padding: 0 15px;
  }
}
@media screen and (max-width: 768px) {
  .chat-container {
    padding: 0 34px;
  }
  .message {
    width: 190px;
  }
  .accept {
    width: 120px;
  }
  .not-accept {
    width: 159px;
  }
}
@media screen and (max-width: 700px) {
  .message {
    width: 170px;
  }
  .not-accept {
    width: 139px;
  }
  .accept {
    width: 100px;
  }
}
@media screen and (max-width: 640px) {
  .message {
    width: 100px;
  }
  .not-accept {
    width: 120px;
  }
  .accept {
    width: 70px;
  }
}
@media screen and (max-width: 520px) {
  .flex-container {
    flex-wrap: wrap;
    position: relative;
  }
  .actions {
    margin-left: 0;
    margin-top: 15px;
    width: 100%;
  }
  .not-accept {
    width: 150px;
  }
  .accept {
    width: 120px;
  }
  .message {
    width: auto;
  }
  .date-request {
    position: absolute;
    right: 0;
    top: 13px;
    align-items: flex-start;
  }
  .from,
  .name {
    white-space: normal;
  }
}
@media screen and (max-width: 480px) {
  .chat-container {
    padding: 0 20px;
  }
}
</style>
