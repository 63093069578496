<template>
  <div class="dialog-header">
    <!--
    <div class="title">
      <img v-if="isMale" src="./images/male.svg" class="male"/>
      <img v-else src="./images/female.svg" class="female"/>
    </div>
    <div class="description">{{ description }}</div>
    -->
    <img src="../images/close-icon.svg" class="close" @click="$emit('close')"/>
  </div>
</template>

<script>
import {PET_DIALOG} from '@/store/types'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)
/*
const getMonthString = (from, to) => {
  const diff = dayjs(to).diff(from, 'month')
  let res = ''

  let yearPrefix = diff >= 24 ? 's' : ''
  let monthPrefix = ![0, 1].includes(diff % 12) ? 's' : ''

  if (diff >= 12) {
    res = `${Math.floor(diff / 12)} year${yearPrefix} `
  }

  if (diff % 12 !== 0) {
    res += `${parseInt(diff % 12)} month${monthPrefix}`
  }

  if (!res) {
    return dayjs(from).toNow(true)
  }

  return res
}*/
/*
birth_day: (...)
birth_month: (...)
birth_year: (...)
breed: (...)
chip_number: (...)
color: (...)
color_pattern: (...)
gender: "male"
id: 364
info: "it is a my dog!"
name: "Pet1111"
passport_num: "2304000005"
photos: Array(1)
  0:
    uid: "tr1xgjxdfl7xhcolohan3ogg1nyf2ivb5qcqxezcbxiswr288a"
    url: "/server/api/pets/364/photo/tr1xgjxdfl7xhcolohan3ogg1nyf2ivb5qcqxezcbxiswr288a"

species: Object
  id: 2
  name: "dog"


sterilized: "normal"
 */
export default {
  name: `PetDialogHeader`,
  computed: {
    isMale() {
      return this.passport.gender === 'male'
    },
    passport() {

      return this.$store.getters[PET_DIALOG.GETTERS.PASSPORT]
    },
    description() {
      return `${this.passport.species.name} / ${this.passport.birth_year ?? '????'}-${(this.passport.birth_month ?? '??').toString().padStart(2, '0')}-${(this.passport.birth_day ?? '??').toString().padStart(2, '0')} `
      /*
      if (this.passport.birthday) {
        return `${this.passport.species_code} / ${getMonthString(this.passport.birthday, new Date())}`
      } else {
        return `${this.passport.species_code}`
      }*/
    }
  }
}
</script>

<style scoped>
.male {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.female {
  width: 31px;
  height: 31px;
  margin-left: 10px;
}

.dialog-header {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.title {
  font-size: 24px;
  font-weight: 600;
  line-height: 0.83;
  color: #000000;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
}

.description {
  font-size: 14px;
  color: #9c9c9c;
}

.close {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}
</style>
