export default {
  BACKEND_URL: process.env.VUE_APP_BACKEND_URL || "http://0.0.0.0:8087", //process.env.VUE_APP_BACKEND_URL || `http://207.154.207.71:8003`,
  WS_BACKEND_URL:
    process.env.VUE_APP_BACKEND_WS_URL || "ws://0.0.0.0:8087",

  AUTH_KEY: `token`,
  // https://trello.com/c/IpYkEpOb
  CURRENT_CHAT_REQUEST_KEY: `currentChat`,
  CURRENT_QUESTION_REQUEST_KEY: `currentQuestion`,
  LAST_ACCEPTED_CHAT_REQUEST_ID_KEY: `lastAcceptedRequest`,

  GOOGLE_KEY: process.env.VUE_APP_GOOGLE_KEY || ``,

  AGORA_ID: process.env.VUE_APP_AGORA_ID || ``,

  REDIRECT_TO_HTTPS: process.env.VUE_APP_REDIRECT_TO_HTTPS || false,

  RECONNECT_TIMEOUT: 5000 // 5 sec
}
