import {QUESTIONS} from '@/store/types'
// import socket from '@/lib/network/socket'
import config from '@/config'
import network from "@/lib/network";

const state = () => ({
  questions: [],
  currentQuestionId: +localStorage.getItem(config.CURRENT_CHAT_REQUEST_KEY),
  /*
  lastAcceptedRequestId: +localStorage.getItem(
    config.LAST_ACCEPTED_CHAT_REQUEST_ID_KEY
  )*/
})

const getters = {

  [QUESTIONS.GETTERS.GET_QUESTIONS]: (questionState) => questionState.questions,
  /*
  [CHAT_REQUESTS.GETTERS.REQUEST_BY_ID]: (state) => (id) => function () {
    console.log('getters.request_by_id', id, state.chat_requests)
    return state.chat_requests.find((request) => request.id === id)
  }(),
  [CHAT_REQUESTS.GETTERS.ISSET_ACCEPTED_CHAT_REQUEST]: (state) =>
    !!state.chat_requests.find((request) => !!request.redirectButtonShowed)*/
}

const mutations = {
  [QUESTIONS.MUTATIONS.SET_QUESTIONS]: (state, list) => {
    console.log('ql', list)

    state.questions = list
    /*
    state.chat_requests = list.map((item) => ({
      ...item,
      redirectButtonShowed: false,
      accepted: false,
      chat: null
    }))(*/
  },
  [QUESTIONS.MUTATIONS.REMOVE]: (state, questionId) => {
    state.questions = state.questions.filter(
      (request) => request.id !== questionId
    )
  },
  [QUESTIONS.MUTATIONS.ADD_QUESTION]: (state, question) => {
    console.log('q',question)
    state.questions.push(question)
    /*
    state.chat_requests = list.map((item) => ({
      ...item,
      redirectButtonShowed: false,
      accepted: false,
      chat: null
    }))(*/
  },


  /*
  [CHAT_REQUESTS.MUTATIONS.ADD_CHAT_REQUESTS]: (state, ticket) => {
    const requests = state.chat_requests.filter(
      (request) => request.id !== ticket.id
    )

    ticket = {
      ...ticket,
      redirectButtonShowed: false,
      accepted: false,
      chat: null
    }

    state.chat_requests.splice(
      0,
      state.chat_requests.length,
      ticket,
      ...requests
    )
  },
  [CHAT_REQUESTS.MUTATIONS.SET_CHAT_REQUESTS]: (state, list) => {
    state.chat_requests = list.map((item) => ({
      ...item,
      redirectButtonShowed: false,
      accepted: false,
      chat: null
    }))
  },
  [CHAT_REQUESTS.MUTATIONS.SHOW_REDIRECT_BUTTON]: (state, ticket) => {
    const request = state.chat_requests.find(
      (request) => request.id === ticket.id
    )
    if (request) {
      request.redirectButtonShowed = true
      request.chat = ticket.chat
    }
  },
  [CHAT_REQUESTS.MUTATIONS.REMOVE_ALL_REQUESTS]: (state) => {
    state.chat_requests = []
  },
  [CHAT_REQUESTS.MUTATIONS.REMOVE_REQUEST]: (state, requestId) => {
    state.chat_requests = state.chat_requests.filter(
      (request) => request.id !== requestId
    )
  },
  [CHAT_REQUESTS.MUTATIONS.ENABLE_REQUEST_ACCEPTION]: (state, pk) => {
    console.log('acception', pk, state.chat_requests);
    const request = state.chat_requests.find((request) => request.pk === pk)
    if (request) {
      request.accepted = true
    }
  }*/
}

const actions = {
  [QUESTIONS.ACTIONS.COMPLETE]({commit}, {question, answer}) {
    network.completeQuestion(question, answer).then(function () {
      commit(QUESTIONS.MUTATIONS.REMOVE, question.id)
    });

  }
  /*
  [CHAT_REQUESTS.ACTIONS.ACCEPT_REQUEST]({commit}, id) {
    socket.send({
      ticket_accept: {ticket_pk: id, is_accepted: true}
    })

    commit(CHAT_REQUESTS.MUTATIONS.ENABLE_REQUEST_ACCEPTION, id)
  },
  [CHAT_REQUESTS.ACTIONS.DO_NOT_ACCEPT_REQUESTS]({commit}, id) {
    socket.send({
      ticket_accept: {ticket_pk: id, is_accepted: false}
    })

    commit(CHAT_REQUESTS.MUTATIONS.REMOVE_REQUEST, id)
  },
  [CHAT_REQUESTS.ACTIONS.CANCEL_NOT_ACCEPTED]({commit, getters}) {
    const requests = getters[CHAT_REQUESTS.GETTERS.GET_CHAT_REQUESTS]

    requests
      .filter((req) => !req.accepted)
      .forEach((req) => {
        socket.send({
          ticket_accept: {ticket_pk: req.id, is_accepted: false}
        })
        commit(CHAT_REQUESTS.MUTATIONS.REMOVE_REQUEST, req.id)
      })
  }*/
}

export default {
  state,
  getters,
  mutations,
  actions
}
