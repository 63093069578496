<template>
  <button
    :class="{ 'base-button': true, inactive }"
    :hovered="hovered"
    :error="error"
    :disabled="disabled || inactive"
    :type="submit"
    @click="$emit('click')"
    @mouseenter="addClass($event)"
    @mouseleave="removeClass($event)"
  >
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    hovered: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
    submit: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    inactive: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    addClass(e) {
      e.target.classList.add('active-btn')
    },
    removeClass(e) {
      e.target.classList.remove('active-btn')
    }
  }
}
</script>

<style lang="scss" scoped>
$primary: #00aa8d;
$error: #ee5245;
.base-button {
  width: 100%;
  font-size: 14px;
  border: 2px solid $primary;
  color: #fff;
  border-radius: 30px;
  padding: 14px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  outline: none;
  background-color: $primary;
  &:disabled {
    opacity: 0.7;
  }
}
[hovered='true'] {
  color: $primary;
  background: transparent;
}

[error='true'] {
  color: $error;
  background: transparent;
  border: 2px solid $error;
  &:hover:not(:disabled) {
    color: $error !important;
  }
}

$inactive: #9c9c9c;

.inactive,
.inactive:disabled {
  cursor: default;
  opacity: 1 !important;
  background-color: $inactive;
  color: #fff;
  border: 2px solid $inactive;

  &[hovered='true'],
  &[error='true'] {
    background-color: #fff;
    color: $inactive;
  }
}
.active-btn {
  &:hover:not(:disabled) {
    color: $primary;
    background-color: rgba(#000000, 0);
  }
}
</style>
