import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuelidate from 'vuelidate'
import network from './lib/network'
import * as VueGoogleMaps from 'vue2-google-maps'

import windowFocusListener from './lib/focus-blur-window'
import config from '@/config'

Vue.use(VueGoogleMaps, {
  load: {
    key: config.GOOGLE_KEY,
    libraries: 'places'
  }
})

Vue.prototype.$api = network

Vue.use({ Vuelidate, Vuex })

Vue.config.productionTip = false

windowFocusListener(store)

if (location.protocol !== 'https:' && config.REDIRECT_TO_HTTPS) {
  location.replace(`https:${location.href.substring(location.protocol.length)}`)
} else {
  new Vue({
    router,
    store,
    render: (h) => h(App)
  }).$mount('#app')
}
