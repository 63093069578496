<template>
  <div class="base-input">
    <label class="base-input_label">{{ label }}</label>
    <input
      :value="value"
      :placeholder="placeholder"
      :type="type"
      :class="{
        error_valid:
          authError || forgotPasswordError || (passwordError && error)
      }"
      autocomplete="on"
      class="base-input_input"
      v-on="{
        ...$listeners,
        change: (e) => $emit(`change`, e.target.value),
        input: (e) => $emit(`input`, e.target.value)
      }"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { AUTH, PROFILE } from '@/store/types'
export default {
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    error: {
      default: null
    }
  },
  computed: {
    ...mapGetters({
      authError: AUTH.GETTERS.GET_AUTH_ERROR,
      forgotPasswordError: AUTH.GETTERS.GET_AUTH_ERROR,
      passwordError: PROFILE.GETTERS.ERROR
    })
  }
}
</script>

<style lang="scss" scoped>
.base-input {
  margin-bottom: 20px;
}
.base-input_label {
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  color: #000;
}
.base-input_input {
  font-family: inherit;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  color: #000;
  line-height: 1;
  background-color: #fff;
  border: 1px solid #b0ded6;
  border-radius: 5px;
  margin-top: 10px;
  padding: 15px;
  box-sizing: border-box;
  transition: box-shadow 0.3s ease-in-out;
  outline: none;
  &::placeholder {
    font-size: 14px;
    font-weight: 300;
    color: #9c9c9c;
  }
}
.error_valid {
  border: 1px solid #b3493b !important;
  // box-shadow: 0px 0px 17px -2px rgba(184, 54, 37, 1);
}
@media screen and (max-width: 520px) {
  .base-input_input {
    font-size: 16px;
  }
}
</style>
