import { PET_DIALOG } from '@/store/types'
import network from '@/lib/network'

const passport = {
  passport: {
    photos_url: []
  },
  owner: {}
}

const state = () => ({
  passport: { ...passport },
  statuses: {
    passwordFetching: false,
    passportDialogVisibility: false,
    emptyPassportDialogVisibility: false
  }
})

const getters = {
  [PET_DIALOG.GETTERS.PASSPORT]: (state) => state.passport,
  [PET_DIALOG.GETTERS.PASSPORT_FETCHING]: (state) =>
    state.statuses.passwordFetching,
  [PET_DIALOG.GETTERS.PASSPORT_DIALOG_VISIBILITY]: (state) =>
    state.statuses.passportDialogVisibility,
  [PET_DIALOG.GETTERS.EMPTY_PASSPORT_DIALOG_VISIBILITY]: (state) =>
    state.statuses.emptyPassportDialogVisibility
}

const mutations = {
  [PET_DIALOG.MUTATIONS.SET_PASSPORT]: (state, passport) => {
    state.passport = passport
  },
  [PET_DIALOG.MUTATIONS.SET_PASSPORT_FETCHING]: (state, status) => {
    state.statuses.passwordFetching = status
  },
  [PET_DIALOG.MUTATIONS.SET_PASSPORT_DIALOG_VISIBILITY]: (state, status) => {
    state.statuses.passportDialogVisibility = status
  },
  [PET_DIALOG.MUTATIONS.SET_EMPTY_PASSPORT_DIALOG_VISIBILITY]: (
    state,
    status
  ) => {
    state.statuses.emptyPassportDialogVisibility = status
  }
}

const actions = {
  async [PET_DIALOG.ACTIONS.OPEN_PASSPORT]({ commit }, petId) {
    try {
      commit(PET_DIALOG.MUTATIONS.SET_PASSPORT_FETCHING, true)

      if (!petId) {
        commit(PET_DIALOG.MUTATIONS.SET_EMPTY_PASSPORT_DIALOG_VISIBILITY, true)
        return
      }

      const passport = await network.loadPassport(petId)
      commit(PET_DIALOG.MUTATIONS.SET_PASSPORT, passport)
      commit(PET_DIALOG.MUTATIONS.SET_PASSPORT_DIALOG_VISIBILITY, true)
    } catch (e) {
      console.error(e)
    } finally {
      commit(PET_DIALOG.MUTATIONS.SET_PASSPORT_FETCHING, false)
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
