<template>
  <div class="system">
    <p class="text-center">
      {{ date }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    time: {
      type: String,
      required: true
    }
  },
  computed: {
    nameMonth() {
      return [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ]
    },
    date() {
      const currentTime = new Date(this.time)
      const year = currentTime.getFullYear()
      const month = this.nameMonth[currentTime.getMonth()]
      const day = currentTime.getDate()
      return `${day} ${month} ${year}`
    }
  }
}
</script>

<style lang="postcss">
.system {
  position: relative;
  height: 1px;
  margin-bottom: 20px;
  background: #ebebeb;
}

.system p {
  position: absolute;
  top: 0;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  padding: 10px;
  color: #939393;
}
</style>
