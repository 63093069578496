<template>
  <BaseLayout>
    <Header class="header">
      <HeaderTitle class="title-helper">Questions</HeaderTitle>
    </Header>
    <div class="c-wrap" style="overflow-y: scroll">
      <ConnectionFail v-if="isReconnecting"/>
      <div v-if="requests.length" ref="block" class="c-request">
        <!--
        <div v-for="request in requests" :key="request.id">{{ request }}</div>-->
        <Question
          v-for="request in requests"
          :key="request.id"
          :question="request">

        </Question>
        <!--
        <ChatRequest
          v-for="request in requests"
          :key="request.id"
          :id="request.id"
          :pk="request.pk"
          :passport="request.passport_pk"
          :from="request.owner.address"
          :name="request.owner.name"
          :avatar="request.owner.image_url"
          :finished="request.finished_in"
          :accepted="request.accepted"
          :chat="request.chat"
          :show-redirect-button="request.redirectButtonShowed"
        />

      -->
      </div>
      <div v-else class="not-requests">
        <div :class="{ 'fix-not-wrapper': isPanelOpen }" class="not-wrapper">
          <img src="@/assets/icons/pet.svg" alt="pet"/>
          <p>No questions at this time</p>
        </div>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/layout/Base'
// import ChatRequest from '@/components/ChatRequest'
import Header from '@/components/Header'
import HeaderTitle from '@/components/HeaderTitle'
import {QUESTIONS, COMMON, SIDEBAR_OPEN} from '@/store/types'
import ConnectionFail from '@/components/ConnectionFail'
import Question from "@/views/Question.vue";

export default {
  components: {Question, BaseLayout, Header, HeaderTitle, ConnectionFail},
  computed: {
    requests() {
      let r = this.$store.getters[QUESTIONS.GETTERS.GET_QUESTIONS]

      return r
    },
    isPanelOpen() {
      return this.$store.getters[SIDEBAR_OPEN.GETTERS.GET_IS_NAV_OPEN]
    },
    isReconnecting() {
      return this.$store.getters[COMMON.GETTERS.RECONNECTING]
    }
  },
  beforeRouteLeave(to, from, next) {

    next()
  }
}
</script>

<style lang="scss" scoped>
.c-wrap {
  height: calc(100% - 82px);
  position: relative;
  overflow: hidden;
}

.title-helper {
  flex: 1;
  white-space: nowrap;
}

.c-request {
  margin-top: 30px;
}

.not-requests {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;

  p {
    font-size: 14px;
    font-family: ProximaNova;
    color: #929292;
    margin-top: 10px;
  }
}

.not-wrapper {
  position: absolute;
  width: 190px;
  left: 50%;
  top: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, -50%);
  transition: 0.7s;
}

.header /deep/ .header-burger {
  width: unset;
}

@media screen and (max-width: 768px) {
  .not-requests {
    position: relative;
  }
  .title-helper {
    padding-left: 30px;
  }
  .fix-not-wrapper {
    left: 30%;
  }
}

@media screen and (max-width: 650px) {
  .fix-not-wrapper {
    left: 25%;
  }
}

@media screen and (max-width: 490px) {
  .fix-not-wrapper {
    left: 50%;
  }
}

@media screen and (max-width: 480px) {
  .title-helper {
    padding-left: 20px;
  }
}
</style>
