<template>
  <FinishedAt :date="date" class="finished-at">
    <img src="./images/desktop-timer.svg" class="icon" />
  </FinishedAt>
</template>

<script>
import FinishedAt from './FinishedAt'
export default {
  name: 'FinishedAtDesktop',
  components: {
    FinishedAt
  },
  props: {
    date: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.icon {
  width: 26px;
  height: 30px;
  margin-right: 10px;
}
.finished-at {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71;
  color: #000000;
}
@media screen and (max-width: 768px) {
  .finished-at {
    display: none;
  }
}
</style>
