<template>
  <BaseLayout>
    <FinishedAtMobile :date="chat.finished_at" />
    <ResumeModal v-if="showResumeModal" />
    <CompleteModal v-if="showCompleteModal" />
    <transition name="fade">
      <ImagesSlider v-if="sliderVisibility" />
    </transition>
    <ChatHeader class="chat-header-column">
      <div class="wrapper-chat-person">
        <div class="header-user-wrapper">
          <Avatar :image="chat.owner.image_url" :full-name="chat.owner.name" />
          <span class="person-name"> {{ chat.owner.name }}</span>

          <div v-if="chat.status === 'closed'" class="head-closed">
            <p class="head-closet-txt">(Chat is closed)</p>
            <img src="@/assets/icons/lock-grey.svg" />
          </div>
        </div>
        <div class="finished-wrapper">
          <FinishedAtDesktop :date="chat.finished_at" />
        </div>
      </div>
    </ChatHeader>
    <div
      class="c-wrap common-wrapper"
      :class="{ 'is-not-active': chat.status !== 'active' }"
    >
      <Spinner v-if="chatLoading" />
      <div ref="block" class="c-chat" :style="chatStyles">
        <div v-if="!messages.length && !chatLoading" class="not-requests">
          <img src="@/assets/icons/pet.svg" alt="pet" />
          <p>You have no chat messages at the moment.</p>
        </div>
        <PrevMessagesSpinner v-if="hasNext" />
        <Messages ref="messages" />
        <template v-if="chat.status === `closed`">
          <Resume v-if="!resumeIsClear" />
        </template>
      </div>

      <div v-if="chat.pk" class="help-scroll-btn">
        <div
          v-show="showBtnScrollStart"
          class="scroll-top-bottom"
          @click="scrollChatToStart"
        >
          <img
            src="@/assets/arrow-scroll.svg"
            alt="arrow-scroll"
            class="img-scroll"
          />
        </div>
        <div v-if="chat.status === 'active'" class="c-form">
          <ChatForm />
        </div>
        <div v-else class="chat-is-closed">
          <span>Chat is closed <img src="@/assets/icons/lock-grey.svg"/></span>
        </div>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseLayout from '@/layout/Base'
import ChatForm from '@/components/Chat/ChatForm'
import ChatHeader from '@/components/ChatHeader'
import Spinner from '@/components/Spinner'
import PrevMessagesSpinner from '@/components/PrevMessagesSpinner'
import ImagesSlider from '@/components/Chat/ImagesSlider'
import Avatar from '@/components/Avatar'
import ResumeModal from '@/components/Chat/resume-modal/ResumeModal'
import Resume from '@/components/Chat/resume/Resume'
// import ResumeInMessagesForm from '@/components/Chat/resume-in-messages-form/ResumeInMessagesForm'
import {CHAT_COMPLETE, CHAT_IMAGES_SLIDER, CHAT_RESUME, MESSAGES} from '@/store/types'
import Messages from '@/components/Chat/Messages'
import FinishedAtDesktop from '@/components/Chat/finished-at/FinishedAtDesktop'
import FinishedAtMobile from '@/components/Chat/finished-at/FinishedAtMobile'
import CompleteModal from "@/components/Chat/complete-modal/CompleteModal.vue";

export default {
  components: {
    FinishedAtDesktop,
    FinishedAtMobile,
    Messages,
    Resume,
    // ResumeInMessagesForm,
    ResumeModal,
    CompleteModal,
    Avatar,
    ImagesSlider,
    BaseLayout,
    ChatForm,
    ChatHeader,
    Spinner,
    PrevMessagesSpinner
  },
  data: () => ({
    prevBlockHeight: null,
    lastMessage: null,
    chatLoading: false,
    startScroll: true,
    firstLoaded: false,
    counter: 0,
    showBtnScrollStart: false,
    scrollListener: null
  }),
  computed: {
    ...mapGetters({
      resumeIsClear: CHAT_RESUME.GETTERS.RESUME_IS_CLEAR,
      showResumeModal: CHAT_RESUME.GETTERS.FORM_VISIBILITY,
      showCompleteModal: CHAT_COMPLETE.GETTERS.FORM_VISIBILITY,
      sliderVisibility: CHAT_IMAGES_SLIDER.GETTERS.SHOW_SLIDER,
      messages: MESSAGES.GETTERS.MESSAGES,
      chat: MESSAGES.GETTERS.CHAT,
      hasNext: MESSAGES.GETTERS.NEXT_LIST_URL
    }),
    chatStyles() {
      return {
        opacity: +!this.chatLoading
      }
    }
  },
  watch: {
    chat() {
      this.firstLoaded = false
      this.$refs.block.addEventListener(`scroll`, this.scrollHandler)
      this.scrollChatToStart()
    },
    chatLoading(loading) {
      if (!loading && !this.firstLoaded) {
        this.scrollChatToStart()
        this.firstLoaded = true
      }
    },
    $route() {
      this.loadChatPage()
      this.$store.commit(
        CHAT_IMAGES_SLIDER.MUTATIONS.SET_SLIDER_VISIBILITY,
        false
      )
    },
    messages(messages, old) {
      if (JSON.stringify(messages) === JSON.stringify(old)) {
        return
      }
      const last = messages[messages.length - 1]
        ? messages[messages.length - 1].pk
        : null
      if (this.lastMessage !== last) {
        this.scrollChatToStart()
      } else {
        this.$nextTick(() => {
          this.$refs.block.scrollTop = this.prevBlockHeight
        })
      }
      this.lastMessage = last
    }
  },
  created() {
    this.loadChatPage()
  },
  mounted() {
    this.$refs.block.addEventListener(`scroll`, this.scrollHandler)
    this.scrollListener = setInterval(this.updatePrevBlockHeight, 50)
    this.updatePrevBlockHeight()
  },
  destroyed() {
    clearTimeout(this.scrollListener)
    this.$store.commit(MESSAGES.MUTATIONS.CLEAR_CURRENT_CHAT)
    this.$store.commit(MESSAGES.MUTATIONS.SET_MESSAGES, [])
  },
  methods: {
    // метод скролла чата к началу, а так же прочие хелперы, это действие обеспечивающие
    scrollChatToStart() {
      this.$nextTick(() => {
        this.$refs.block.scrollTop = this.$refs.block.scrollHeight
        this.startScroll = true
        this.showBtnScrollStart = false
      })
    },
    updatePrevBlockHeight() {
      this.prevBlockHeight = this.$refs.block.clientHeight
    },
    scrollHandler() {
      if (this.startScroll) {
        this.counter = this.$refs.block.scrollTop
        this.startScroll = false
      }
      this.showBtnScrollStart = this.counter > this.$refs.block.scrollTop + 400
      if (this.hasNext && this.$refs.block.scrollTop < 100) {
        this.$store.dispatch(MESSAGES.ACTIONS.LOAD_NEXT)
      }
    },
    async loadChatPage() {
      this.chatLoading = true
      await this.$store.dispatch(MESSAGES.ACTIONS.LOAD_CHAT_PAGE)
      this.chatLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.head-closed {
  display: flex;
  align-items: center;
  margin: 0 20px;
}
.head-closet-txt {
  font-size: 12px;
  line-height: 1;
  color: #9c9c9c;
  margin-right: 10px;
  white-space: nowrap;
}
.c-wrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
  max-height: calc(100% - 82px);
  position: relative;
  overflow: hidden;
  @media screen and (max-width: 480px) {
    padding-bottom: 0 !important;
  }
}
.c-chat {
  width: calc(100% + 20px);
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  transition: 0.3s ease-in-out;
}
.help-message {
  &:first-child {
    margin-top: auto;
  }
}
.chat-is-closed {
  bottom: 30px;
  left: 0;
  right: 0;
  height: 40px;
  border-radius: 8px;
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10%;
}
.chat-is-closed span {
  font-size: 12px;
  color: #727272;
  display: flex;
  align-items: center;
}
.avatar {
  width: 30px;
  height: 30px;
  flex-shrink: 0;
}
.chat-is-closed span img {
  margin-left: 10px;
}
.wrapper-chat-person {
  position: relative;
  display: flex;
  align-items: center;
}
.header-user-wrapper {
  width: 50%;
  display: flex;
  align-items: center;
}
.finished-wrapper {
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .finished-wrapper {
    display: none;
  }
  .header-user-wrapper {
    width: 100%;
  }
}
.wrapper-chat-person {
  display: flex;
  align-items: center;
  width: 100%;
  // max-width: 250px;
  overflow: hidden;
  flex: 1;
  position: relative;
}
.person-name {
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  line-height: 0.83;
  color: #000;
  margin-left: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
//
.not-requests {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 200px;

  p {
    font-size: 14px;
    font-family: ProximaNova;
    color: #929292;
    margin-top: 10px;
  }
}
.help-scroll-btn {
  position: relative;
  padding: 10px 0;
}
.scroll-top-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  opacity: 0.9;
  border-radius: 20px;
  background-color: #00aa8d;
  position: absolute;
  top: -70px;
  right: 0;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.img-scroll {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
// MEDIA QUERIES //
@media (max-width: 768px) {
  .wrapper-chat-person {
    margin-left: 30px;
  }
}
@media (max-width: 520px) {
  .c-wrap {
    padding-top: 0;
  }
  .c-chat {
    padding-top: 30px;
  }
  .wrapper-chat-person {
    max-width: 220px;
  }
  .person-name {
    font-size: 18px;
    line-height: 1.11;
    margin-left: 10px;
  }
  .head-closet-txt {
    display: none;
  }
}
@media (max-width: 480px) {
  .wrapper-chat-person {
    margin-left: 20px;
  }
}
@media (max-width: 380px) {
  .wrapper-chat-person {
    max-width: 170px;
  }
}
@media (max-width: 370px) {
  .person-name {
    white-space: normal;
  }
}
</style>
