<template>
  <div>
    <EmptyPassportDialog v-if="emptyPassportDialogVisibility" />
    <PassportDialog v-if="passportDialogVisibility" />
    <PassportSpinner v-if="loading" />
  </div>
</template>

<script>
import EmptyPassportDialog from './empty-passport-dialog/EmptyPassportDialog'
import PassportDialog from './passport-dialog/PetPassportDialog'
import PassportSpinner from './passport-spinner/PassportSpinner'
import { mapGetters } from 'vuex'
import { PET_DIALOG } from '@/store/types'
export default {
  name: `PetDialogs`,
  components: {
    EmptyPassportDialog,
    PassportDialog,
    PassportSpinner
  },
  computed: {
    ...mapGetters({
      loading: PET_DIALOG.GETTERS.PASSPORT_FETCHING,
      passportDialogVisibility: PET_DIALOG.GETTERS.PASSPORT_DIALOG_VISIBILITY,
      emptyPassportDialogVisibility:
        PET_DIALOG.GETTERS.EMPTY_PASSPORT_DIALOG_VISIBILITY
    })
  }
}
</script>

<style scoped></style>
