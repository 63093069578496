export default (client) => ({
  // /server/api/users/profile-data
  loadProfileInfo: () => client.get(`/server/api/users/profile-data`),
  getProfileAvatar: (user_id) =>
    (async function () {
      let img = await client.get(`/server/api/users/${user_id}/avatar`)
      return img
    }),
  changePassword: ({
                     password_old,
                     password_new
                   }) => client.put(`/api/moderator/password/`, {password_old, password_new}),
  saveProfileInfo: (data) => client.put(`/api/moderator/`, data),
  chaneProfileImage: (data) => client.post(`/api/moderator/image/`, data),

  getVideoChatCredentials: (chatId) => client.get(`/chat/api/chat/${chatId}/video-token/`),

  closeVideoChat: (chatId) => client.put(`/chat/api/chat/${chatId}/close/`),

  login: (data) => client.post(`/server/api/doctors/sign-in`, data),

  getChatInfo: (pk) => (async function () {
    let resp = await client.get(`/server/api/chats/${pk}/info`)
    let chat = resp.res
    return {
      pk: chat.pk,
      id: chat.id,
      status: chat.status,
      passport: chat.pet ? chat.pet.passport_num : null,
      pet_id: chat.pet ? chat.pet.id : null,
      owner: {
        id: chat.client.id,
        pk: chat.client.id,
        address: chat.client.address,
        name: chat.client.name,
        image_url: chat.client.image
      },
      finished_in: 1000,
      accepted: false,
      chat: 1,
      redirectButtonShowed: true,
      unread_amount: chat.unread_amount
    }
  })(),
  uploadPhotos: (chatId, data) => client.post(`/server/api/chats/${chatId}/images`, data),
  saveResume: (id, resume) => client.post(`/server/api/chats/${id}/resume`, resume),
  uploadResumePhotos: (data) => client.post(`/chat/api/media/resume/`, data),
  getChatMessages: (chatId) => client.get(`/server/api/chats/${chatId}/messages`),
  getNextMessages: (url) => client.get(url),
  getModeratorChats: (showAll) => client.get(`/server/api/chats?show_all=${showAll}`),
  restore: (data) => client.put(`/api/moderator/restore/`, data),
  completeQuestion: (question, answer) =>
    (async function () {
      let data = {
        doctor_response: answer, status: 'completed'
      };
      let res = await client.post(`/server/api/questions/${question.service_uuid}/complete`, data)
      return res.res;

    })(),

  loadPassport: (id) => (async function () {
    let res = await client.get(`/server/api/pets/${id}`)
    return res.res

  })(),

})
