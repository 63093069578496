<template>
  <div class="person-container" @click="$emit('click')">
    <div v-if="complited === 'closed'" class="substrate" />
    <div class="person-block px20">
      <div class="person">
        <div class="person-avatar">
          <slot name="person" />
          <div v-if="read === 'active'" class="notice" />
        </div>
        <span class="person-name">{{ name }}</span>
      </div>
      <NoticeCounter
        v-if="complited !== 'closed' && read > 0"
        class="help-notice"
      >
        {{ read }}
      </NoticeCounter>
      <img
        v-if="complited === 'closed'"
        src="@/assets/icons/lock.svg"
        alt="lock"
        class="lock"
      />
    </div>
  </div>
</template>

<script>
import NoticeCounter from '@/components/NoticeCounter.vue'
export default {
  components: { NoticeCounter },
  props: {
    name: {
      type: String,
      required: true
    },
    read: {
      type: Number,
      required: false
    },
    complited: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.px20 {
  padding: 0 20px;
  box-sizing: border-box;
}
.person-container {
  display: flex;
  width: 100%;
  position: relative;
  transition: 0.3s ease-in-out;
  &:hover {
    background: #008b73;
    cursor: pointer;
  }
}
.substrate {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
  background: rgba(0, 0, 0, 0.1);
}
.person-block {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.2s;
  position: relative;
}
.person {
  display: flex;
  align-items: center;
  width: 100%;
}
.person-avatar {
  margin-right: 15px;
  height: 30px;
  width: 30px;
  flex-shrink: 0;
  position: relative;
}
.help-notice {
  width: 28px;
  position: absolute;
  top: 50%;
  right: 38px;
  border-radius: 50%;
  border: 2px solid #fff;
  color: #fff;
  background-color: #00aa8d;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.lock {
  position: absolute;
  top: 50%;
  right: 44px;
  transform: translateY(-50%);
}
.person-name {
  font-weight: 600;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 75px);
}
.person-notice {
  height: 24px;
  width: 28px;
  border-radius: 50%;
  background: #fff;
  color: #00aa8d;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
