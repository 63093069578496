export const validate = (dict, rules) => {
  const validatorKeys = Object.keys(rules)
  const errors = {}

  validatorKeys.forEach((validatorKey) => {
    const validators = rules[validatorKey]
    validators.forEach((validator) => {
      if (errors[validatorKey]) {
        return
      }
      errors[validatorKey] = validator(dict[validatorKey])
    })
  })
  return errors
}

export const hasErrors = (errorsObject) => {
  return Object.keys(errorsObject).reduce(
    (hasError, key) => hasError || !!errorsObject[key],
    false
  )
}
