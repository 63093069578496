import { NOTIFICATIONS } from '@/store/types'
import { browserNotification } from '@/lib/notifications'

const state = () => ({
  // {
  //   text
  //   title
  //   image
  //   key
  // }
  notifications: []
})

const getters = {
  [NOTIFICATIONS.GETTERS.LIST]: (state) => state.notifications
}

const mutations = {
  [NOTIFICATIONS.MUTATIONS.ADD]: (state, notification) => {

    let audio = new Audio(require("@/assets/meow.mp3"));
    audio.play();
    state.notifications.push(notification)
  },
  [NOTIFICATIONS.MUTATIONS.DELETE_BY_KEY]: (state, key) => {
    const index = state.notifications.find(
      (notification) => notification.key === key
    )
    if (index !== -1) {
      state.notifications.splice(index, 1)
    }
  }
}

const actions = {
  [NOTIFICATIONS.ACTIONS.NOTIFY](
    { commit, getters },
    { text, image, title, withBrowserNotification, url }
  ) {
    const waitOrCreate = () => {
      if (getters[NOTIFICATIONS.GETTERS.LIST].length >= 5) return

      clearInterval(interval)
      const key = Date.now()
      commit(NOTIFICATIONS.MUTATIONS.ADD, {
        key,
        image,
        title,
        text,
        url
      })

      if (withBrowserNotification) {
        browserNotification({
          title,
          text,
          url,
          imageUrl: image
        })
      }

      setTimeout(() => commit(NOTIFICATIONS.MUTATIONS.DELETE_BY_KEY, key), 5000)
    }

    const interval = setInterval(waitOrCreate, 500)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
