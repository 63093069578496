<template>
  <div class="password-spinner">
    <img src="~@/assets/common/white-spinner.svg" class="loader" />
  </div>
</template>

<script>
export default {
  name: `PasswordSpinner`
}
</script>

<style lang="scss" scoped>
.password-spinner {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 3000;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
}
</style>
