<template>
  <transition name="out">
    <div v-if="opened" class="video-chat-request">
      <div class="dialog">
        <div class="title">Video Chat</div>
        <div class="avatar-wrapper">
          <Avatar :image="chatImage" :full-name="message.name" class="avatar" />
          <div class="camera-icon">
            <img src="@/assets/video-chat/camera.svg" alt="">
          </div>
        </div>
        <div class="user-name">{{ message.name }}</div>
        <div class="actions">
          <div class="button call" @click="createChat" />
          <div class="button cancel" @click="closeChat" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Avatar from '@/components/Avatar'
import { CHAT_LIST, VIDEO_CHATS } from '@/store/types'
import { createVideoChat } from '@/lib/video-chats/video-call'
export default {
  name: `VideoChatRequestsDialog`,
  components: {
    Avatar
  },
  props: {
    message: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    opened: false
  }),
  mounted() {
    document.addEventListener('keyup', this.keyboardEscHandler)
    this.opened = true
  },
  destroyed() {
    document.removeEventListener('keyup', this.keyboardEscHandler)
  },
  computed: {
    chatImage() {
      const chats = this.$store.getters[CHAT_LIST.GETTERS.GET_CHAT_LIST]
      const chat = chats.find((chat) => chat.pk === this.message.chat)
      return chat ? chat.owner.image_url : ``
    }
  },
  methods: {
    keyboardEscHandler(e) {
      if (e.keyCode === 27) {
        this.closeChat()
      }
    },
    createChat() {
      createVideoChat(this.message.chat)
      this.$store.commit(VIDEO_CHATS.MUTATIONS.REMOVE_REQUEST, this.message)
    },
    closeChat() {
      this.$store.dispatch(VIDEO_CHATS.ACTIONS.CLOSE_REQUEST, this.message)
    }
  }
}
</script>

<style lang="scss" scoped>
// анимации
.out-enter-active,
.out-leave-active {
  transition: all 0.3s;
}
.out-enter-active .dialog,
.out-leave-active .dialog {
  transition: all 0.2s;
}
.out-enter .dialog,
.out-leave-active .dialog {
  transform: scale(0);
  opacity: 0;
}
.video-chat-request {
  position: fixed;
  width: 100vw;
  height: auto;
  min-height: 100vh;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 10000;
}
.title {
  font-size: 24px;
  font-weight: 600;
  line-height: 0.83;
  margin-bottom: 30px;
}
.avatar-wrapper {
  width: 140px;
  height: 140px;
  position: relative;
}
.avatar {
  width: 100%;
  height: 100%;
}
.camera-icon {
  width: 58px;
  height: 58px;
  border: solid 4px #fafafa;
  background-color: #00aa8d;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.actions {
  display: flex;
  width: 160px;
  display: flex;
  justify-content: space-between;
}
.button {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  cursor: pointer;

  &.call {
    background: url('~@/assets/video-chat/telephone.svg') center no-repeat;
    background-size: 28px;
    background-color: #00aa8d;
  }
  &.cancel {
    background: url('~@/assets/video-chat/cancel.svg') center no-repeat;
    background-size: 35px;
    background-color: #ee5245;
  }

  &:hover {
    opacity: 0.8;
  }
}
.user-name {
  margin-top: 19px;
  font-size: 16px;
  font-weight: 600;
  line-height: 0.88;
  margin-bottom: 30px;
}
.dialog {
  opacity: 1;
  transform: scale(1);
  color: #000;
  width: 340px;
  border-radius: 8px;
  box-shadow: 1px 2px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
