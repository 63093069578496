import { VIDEO_CHATS } from '@/store/types'
import { videoChats } from '@/lib/video-chats/video-call'
import network from '@/lib/network'

const state = () => ({
  requests: [],
  videoChats: [] // [{id, isFullScreen, startDate}]
})

const getters = {
  [VIDEO_CHATS.GETTERS.REQUESTS]: (state) => state.requests,
  [VIDEO_CHATS.GETTERS.REQUEST_BY_CHAT]: (state) => (chatId) =>
    state.requests.find((request) => request.chat === chatId),
  [VIDEO_CHATS.GETTERS.CHATS]: (state) => state.videoChats // [{id, isFullScreen, startDate}]
}

const mutations = {
  [VIDEO_CHATS.MUTATIONS.ADD_REQUEST]: (state, message) => {
    state.requests = [
      ...state.requests.filter((request) => request.chat !== message.chat),
      message
    ]
  },
  [VIDEO_CHATS.MUTATIONS.REMOVE_REQUEST]: (state, message) => {
    state.requests = state.requests.filter((request) => request !== message)
  },
  [VIDEO_CHATS.MUTATIONS.REMOVE_REQUEST_BY_CHAT]: (state, chatId) => {
    state.requests = state.requests.filter((request) => request.chat !== chatId)
  },

  [VIDEO_CHATS.MUTATIONS.SET_CHAT_LIST]: (state, chats) => {
    state.videoChats = chats
  },
  [VIDEO_CHATS.MUTATIONS.ADD_CHAT]: (state, { id, endDate }) => {
    state.videoChats = [
      ...state.videoChats.filter((chat) => id !== chat.id),
      { id, isFullScreen: false, endDate }
    ]
  },

  [VIDEO_CHATS.MUTATIONS.TOGGLE_FULL_SCREEN]: (state, chat) => {
    const toggleChat = state.videoChats.find((c) => c === chat)
    if (toggleChat) {
      toggleChat.isFullScreen = !toggleChat.isFullScreen
    }
  },
  [VIDEO_CHATS.MUTATIONS.REMOVE_CHAT]: (state, chatId) => {
    state.videoChats = state.videoChats.filter((chat) => chatId !== chat.id)
  }
}

const actions = {
  async [VIDEO_CHATS.ACTIONS.CLOSE_ALL]({ commit }) {
    await videoChats.closeAll()
    commit(VIDEO_CHATS.MUTATIONS.SET_CHAT_LIST, [])
  },
  [VIDEO_CHATS.ACTIONS.CLOSE_REQUEST]({ commit }, message) {
    try {
      commit(VIDEO_CHATS.MUTATIONS.REMOVE_REQUEST, message)
      network.closeVideoChat(message.chat)
    } catch (e) {
      console.error(e)
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
