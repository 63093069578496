<template>
  <div class="app-notifications">
    <transition-group name="slide">
      <div
        v-for="notification in notifications"
        :key="notification.key"
        :class="{ notification: true, 'has-url': !!notification.url }"
        @click="notificationClickHandler(notification)"
      >
        <Avatar :image="notification.image" :full-name="notification.title" />
        <div class="content">
          <div class="title">{{ notification.title }}</div>
          <div class="text">{{ notification.text }}</div>
        </div>
        <button class="close" @click.stop="close(notification)">
          <img src="~@/assets/common/notification-close.svg" />
        </button>
      </div>
    </transition-group>
  </div>
</template>

<script>
import Avatar from '@/components/Avatar'
import { NOTIFICATIONS } from '@/store/types'
export default {
  name: 'AppNotifications',
  components: {
    Avatar
  },
  computed: {
    notifications() {
      return this.$store.getters[NOTIFICATIONS.GETTERS.LIST]
    }
  },
  methods: {
    notificationClickHandler(notification) {
      if (notification.url) {
        this.$router.push(notification.url)
        this.close(notification)
      }
    },
    close(notification) {
      this.$store.commit(
        NOTIFICATIONS.MUTATIONS.DELETE_BY_KEY,
        notification.key
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.app-notifications {
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 20px;
  height: auto;
  max-height: 100vh;
  overflow: hidden;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 324px;
}
.notification {
  display: flex;
  width: 100%;
  margin-top: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.14);
  background-color: #ffffff;
  padding: 20px;
  position: relative;
  &.has-url {
    cursor: pointer;
  }
}
.content {
  flex: 1;
  margin-left: 20px;
}
.avatar {
  width: 40px;
  height: 40px;
}
.title {
  font-size: 14px;
  font-weight: 600;
}
.text {
  margin-top: 5px;
  font-size: 12px;
  color: #818181;
}
.close {
  background: none;
  outline: none;
  padding: 10px;
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  img {
    width: 8px;
    height: 8px;
  }
}

@media screen and (max-width: 768px) {
  .app-notifications {
    width: 100%;
  }
}

@media screen and (max-width: 550px) {
  .notification {
    padding: 15px 10px;
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.6s ease;
}
.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
}
</style>
