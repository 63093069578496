<template>
  <div class="google-autocomplete" @click.stop="focused = true">
    <BaseInput
      v-model="address"
      placeholder="Enter location"
      @keydown="keydownHandler"
      @keyup="search(address)"
      @focus="focused = true"
    />
    <Spinner v-if="loading" class="spinner" />
    <div v-if="locations.length && focused" class="variants">
      <div
        v-for="(location, index) in locations"
        :key="location.id"
        :class="{ variant: true, active: activeIndex === index }"
        @click="pickItem(location)"
      >
        {{ location.address }}
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput'
import debounce from '@/lib/helpers/debounce'
import Spinner from '@/components/Spinner.vue'

import {
  getPlace,
  getPlacesList
} from '@/views/profile/content/location-form/autocomplete/placesAutocomplete'

export default {
  name: `GoogleAutocomplete`,
  components: {
    BaseInput,
    Spinner
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        address: ``,
        lat: null,
        lang: null
      })
    }
  },
  data() {
    return {
      loading: false,
      focused: false,
      activeIndex: 0,
      address: ``,
      locations: [],
      searchLocations: debounce(this.updateVariants, 500)
    }
  },
  watch: {
    address(v) {
      if (!v.trim()) {
        this.select({
          address: ``,
          lat: null,
          lon: null
        })
      } else {
        this.searchLocations(v)
      }
    },
    value: {
      immediate: true,
      handler(v) {
        this.locations = []
        this.address = v.address
      }
    },
    locations() {
      this.activeIndex = 0
    }
  },
  mounted() {
    document.addEventListener(`click`, this.documentClickHandler)
  },
  destroy() {
    document.removeEventListener(`click`, this.documentClickHandler)
  },
  methods: {
    search(query) {
      this.loading = true
      this.searchLocations(query)
    },
    documentClickHandler() {
      this.focused = false
    },
    keydownHandler(e) {
      if (e.keyCode === 38) {
        this.activeIndex =
          this.activeIndex - 1 < 0
            ? this.locations.length - 1
            : this.activeIndex - 1
      }
      if (e.keyCode === 40) {
        this.activeIndex =
          this.activeIndex + 1 >= this.locations.length
            ? 0
            : this.activeIndex + 1
      }
      if (e.keyCode === 13 && this.locations[this.activeIndex]) {
        this.select(this.locations[this.activeIndex])
      }
    },
    async pickItem(location) {
      try {
        this.loading = true
        const placeData = await getPlace(location.id)
        this.select({
          address: location.address,
          lat: placeData.data.result.geometry.location.lat,
          lon: placeData.data.result.geometry.location.lng
        })
      } catch (e) {
        console.error(`autocomplete error: ${e}`)
      } finally {
        this.loading = false
      }
    },
    async select(location) {
      this.$emit(`change`, {
        address: location.address,
        lat: location.lat,
        lon: location.lon
      })
    },
    updateVariants(query) {
      try {
        getPlacesList(query).then((res) => {
          if (!this.address.trim()) {
            return
          }
          if (res.data.predictions) {
            this.locations = res.data.predictions.map((location) => ({
              id: location.place_id,
              address: location.description
            }))
          }
        })
      } catch (e) {
        console.error(`autocomplete error: ${e}`)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.spinner {
  position: absolute;
  right: 10px;
  top: 4px;
  width: 30px;
  background: transparent;
  left: unset;
  & /deep/ .loader {
    width: 80px;
  }
}
.google-autocomplete {
  position: relative;
}
.variants {
  position: absolute;
  width: 100%;
  top: calc(100% - 5px);
  z-index: 2;
  left: 0;
  border: 1px solid #b0ded6;
  border-top: none;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  background: #fff;
  overflow: hidden;
}
.variant {
  padding: 12px 15px;
  cursor: pointer;
  &:hover,
  &.active {
    background: rgba(179, 224, 216, 0.21);
  }
}
</style>
