import store from '@/store'
import config from '@/config'
import placeholder from '@/assets/user.svg'
import {
  CHAT_REQUESTS,
  CHAT_LIST,
  MESSAGES,
  NOTIFICATIONS,
  COMMON,
  VIDEO_CHATS, QUESTIONS
} from '@/store/types'
import router from '@/router'
import {videoChats} from '@/lib/video-chats/video-call'

const getToken = () => localStorage.getItem(config.AUTH_KEY)

let reconnectionTimeout = null

const createSocket = () => {
  let _socket = null
  return {
    isReconnection: true,
    needConnection: true,
    connect(token) {
      if (_socket) {
        this.disconnect()
      }
      try {
        _socket = new WebSocket(`${config.WS_BACKEND_URL}/ws/api/chats/${token}/connect`)

      } catch (e) {
        console.error('Error while connecting')
      }


      _socket.onopen = () => {
        store.dispatch(CHAT_LIST.ACTIONS.GET_CHAT_LIST)
        socket.needConnection = false
      }

      const onError = () => {
        console.error("Socket not connected!")
        // if (!navigator.onLine) {

        // socket.createReconnectTimeout()
        socket.needConnection = true


      }

      _socket.onclose = onError
      _socket.onerror = onError

      _socket.onmessage = (event) => {
        const chat = store.getters[MESSAGES.GETTERS.CHAT]
        let res = JSON.parse(event.data)

        // chat requests updated
        if (res.question_list) {

          store.commit(
            QUESTIONS.MUTATIONS.SET_QUESTIONS,
            res.question_list
          )
        }
        if (res.tickets_list) {
          store.commit(
            CHAT_REQUESTS.MUTATIONS.SET_CHAT_REQUESTS,
            res.tickets_list
          )
        }
        if (res.new_question) {

          store.commit(
            QUESTIONS.MUTATIONS.ADD_QUESTION,
            res.new_question
          )
        }

        // new chat request
        if (res.ticket_notification) {
          store.commit(
            CHAT_REQUESTS.MUTATIONS.ADD_CHAT_REQUESTS,
            res.ticket_notification
          )
          store.dispatch(NOTIFICATIONS.ACTIONS.NOTIFY, {
            title: res.ticket_notification.owner.name,
            image: res.ticket_notification.owner.image_url || placeholder,
            text: `You have a new chat request`,
            withBrowserNotification: true,
            url: `/`
          })
        }

        // chat request is finished

        if (res.ticket_finished) {
          if (
            store.getters[CHAT_REQUESTS.GETTERS.REQUEST_BY_ID](
              res.ticket_finished.id
            )
          ) {
            if (res.ticket_finished.status === `cancel_by_user`) {
              store.dispatch(NOTIFICATIONS.ACTIONS.NOTIFY, {
                title: res.ticket_finished.owner.name,
                image: res.ticket_finished.owner.image_url || placeholder,
                text: `Chat request rejected by user`
              })
              // не удалять может прийти involved  апи
              return store.commit(
                CHAT_REQUESTS.MUTATIONS.REMOVE_REQUEST,
                res.ticket_finished.id
              )
            }

          }


          if (res.ticket_finished.involved) {
            store.dispatch(CHAT_LIST.ACTIONS.GET_CHAT_LIST)
            store.dispatch(NOTIFICATIONS.ACTIONS.NOTIFY, {
              title: res.ticket_finished.owner.name,
              image: res.ticket_finished.owner.image_url || placeholder,
              text: `Chat request accepted by user`,
              withBrowserNotification: true,
              url: `/chat/${res.ticket_finished.chat}`
            })
            console.log('tf', res.ticket_finished)
            store.commit(CHAT_REQUESTS.MUTATIONS.SHOW_REDIRECT_BUTTON, res.ticket_finished)
            /*
            if (router.currentRoute.name === `ChatRequests`) {
              store.commit(
                CHAT_REQUESTS.MUTATIONS.SHOW_REDIRECT_BUTTON,
                res.ticket_finished
              )
              store.dispatch(CHAT_REQUESTS.ACTIONS.CANCEL_NOT_ACCEPTED)
              return
            }
            store.dispatch(CHAT_REQUESTS.ACTIONS.CANCEL_NOT_ACCEPTED)

             */
          }


          /*
          store.commit(
            CHAT_REQUESTS.MUTATIONS.REMOVE_REQUEST,
            res.ticket_finished.id
          )
          */
        }

        // CHATS
        // message is read
        if (res.message_read_notification) {
          store.commit(
            MESSAGES.MUTATIONS.READ_MESSAGE,
            res.message_read_notification.message_pk
          )
        }
        // new message
        if (res.message) {
          if (res.message.msg_type && chat.pk === res.message.chat) {
            store.dispatch(
              MESSAGES.ACTIONS.LOAD_CURRENT_CHAT,
              router.currentRoute.params.pk
            )
          }

          if (
            res.message.msg_type === `chat_finished_by_mistake` &&
            chat.pk === res.message.chat
          ) {
            store.commit(MESSAGES.MUTATIONS.TOGGLE_CURRENT_CHAT_TYPE, `close`)
          }

          if (res.message.msg_type === `video_chat_end`) {
            store.commit(
              VIDEO_CHATS.MUTATIONS.REMOVE_REQUEST_BY_CHAT,
              res.message.chat
            )
            const videoChat = videoChats.list[res.message.chat]
            if (videoChat) {
              videoChat.close()
            }
          }

          if (res.message.msg_type === `video_chat_start`) {
            if (!videoChats.idsList.includes(res.message.chat)) {
              store.commit(VIDEO_CHATS.MUTATIONS.ADD_REQUEST, res.message)
            }
          }

          if (!res.message.is_mine) {
            store.commit(
              CHAT_LIST.MUTATIONS.INCREMENT_CHAT_LIST_COUNTERS,
              res.message.chat
            )
          }
          store.commit(
            CHAT_LIST.MUTATIONS.INCREMENT_CHAT_DATE,
            res.message.chat
          )


          if (chat.pk === res.message.chat) {
            store.commit(MESSAGES.MUTATIONS.ADD_MESSAGE, res.message)
            store.dispatch(MESSAGES.ACTIONS.READ_MESSAGES, [res.message])
          }

          if (
            chat.pk !== res.message.chat ||
            !store.getters[COMMON.GETTERS.WINDOW_IN_FOCUS]
          ) {
            const messageChat = store.getters[
              CHAT_LIST.GETTERS.GET_CHAT_LIST
              ].find((chat) => chat.pk === res.message.chat)

            store.dispatch(NOTIFICATIONS.ACTIONS.NOTIFY, {
              title: messageChat ? messageChat.owner.name : `New message`,
              image: messageChat ? messageChat.owner.image_url : placeholder,
              text: res.message.text,
              withBrowserNotification: true,
              url: `/chat/${res.message.chat}`
            })
          }
        }
        // chat is closed
        if (res.chat_closed) {
          store.commit(CHAT_LIST.MUTATIONS.CLOSE_CHAT, res.chat_closed.chat_pk)
          if (chat.pk === res.chat_closed.chat_pk) {
            store.commit(MESSAGES.MUTATIONS.CLOSE_CHAT)
          }
        }
      }
    },

    createReconnectTimeout() {
      console.log('Reconnecting...')


      if (socket.isReconnection || !navigator.onLine) {
        return
      }
      clearTimeout(reconnectionTimeout)
      reconnectionTimeout = null
      socket.isReconnection = true
      /**/

      setTimeout(() => {
        reconnectionTimeout = socket.connect(getToken())
      }, config.RECONNECT_TIMEOUT)
    },

    disconnect() {
      _socket.close()
      _socket = null
    },

    send: (data) => _socket.send(JSON.stringify(data))
  }
}

// check connection
setInterval(() => {
  if (socket.needConnection) {
    let token = getToken()
    if (token !== null) {
      socket.connect(getToken())
    }
  }
  store.commit(COMMON.MUTATIONS.SET_RECONNECTING, !navigator.onLine)
}, config.RECONNECT_TIMEOUT)

const socket = createSocket()

export default socket
