import { SIDEBAR_OPEN } from '@/store/types'

const state = () => ({
  isNavOpen: true,
  mobileClick: true
})
const actions = {}
const mutations = {
  [SIDEBAR_OPEN.MUTATIONS.TOGGLE_NAV]: (state) => {
    state.isNavOpen = !state.isNavOpen
  },
  [SIDEBAR_OPEN.MUTATIONS.MOBILE_CLICK]: (state, value) => {
    state.mobileClick = value
  }
}
const getters = {
  [SIDEBAR_OPEN.GETTERS.GET_IS_NAV_OPEN]: (state) => state.isNavOpen,
  [SIDEBAR_OPEN.GETTERS.MOBILE_CLICK]: (state) => state.mobileClick
}

export default {
  state,
  getters,
  mutations,
  actions
}
