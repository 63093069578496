<template>
  <div :class="['system-message', type]">
    <div :class="['icon', type]" />
    <div class="content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: `SystemMessage`,
  props: {
    type: {
      type: String,
      default: `success` // error | success | warning
    }
  }
}
</script>

<style lang="scss" scoped>
.system-message {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 12px;
  border-radius: 8px;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
  width: calc(100% - 80px);
  margin-left: 20px;
  &.warning {
    color: #9f8448;
    background-color: #faedd0;
  }
  &.error {
    background-color: #ffd5d3;
    color: #9f4848;
  }
  &.success {
    background-color: #b1e7de;
    color: #0a7c68;
  }
}
.icon {
  width: 20px;
  height: 20px;
  background-size: contain;
  margin-right: 10px;
  &.success {
    background: url('./images/success.svg');
  }
  &.warning {
    background: url('./images/clock.svg');
  }
  &.error {
    background: url('./images/error.svg');
  }
}
.content {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
