<template>
  <div id="app">
    <VideoChats v-if="isAuth" />
    <router-view />
  </div>
</template>

<script>
import config from './config'
import { PROFILE } from './store/types'
import '@/lib/network/socket'
import socket from '@/lib/network/socket'
import VideoChats from '@/components/video-chats/VideoChats'
import { AUTH } from '@/store/types'

export default {
  components: {
    VideoChats
  },
  computed: {
    isAuth() {
      return this.$store.getters[AUTH.GETTERS.GET_AUTH]
    }
  },
  created() {
    const token = window.localStorage.getItem(config.AUTH_KEY)
    if (token) {
      this.$store.dispatch(PROFILE.ACTIONS.LOAD_INFO)
      socket.connect(token)
    }
  }
}
</script>
