<template>
  <form class="location-form" @submit.prevent="save">
    <Spinner v-if="loading" />
    <div class="header">Location</div>
    <GoogleAutocomplete :value="form" @change="form = { ...$event }" />
    <Map :location="form" />
    <div class="actions">
      <BaseButton class="button">Save</BaseButton>
    </div>
  </form>
</template>

<script>
import { PROFILE } from '@/store/types'
import BaseButton from '@/components/BaseButton'
import GoogleAutocomplete from './autocomplete/GoogleAutocomplete'
import Spinner from '@/components/Spinner'
import { mapGetters } from 'vuex'
import Map from './Map'
export default {
  name: 'LocationForm',
  components: {
    BaseButton,
    GoogleAutocomplete,
    Map,
    Spinner
  },
  data: () => ({
    form: {
      address: ``,
      lat: 0,
      lon: 0
    },
    loading: false
  }),
  computed: {
    ...mapGetters({
      profileInfo: PROFILE.GETTERS.INFO
    })
  },
  watch: {
    profileInfo: {
      immediate: true,
      handler(v) {
        this.form = {
          address: v.address || ``,
          lat: v.lat,
          lon: v.lon
        }
      }
    }
  },
  methods: {
    async save() {
      this.loading = true
      await this.$store.dispatch(PROFILE.ACTIONS.SAVE_INFO, this.form)
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.location-form {
  margin-top: 30px;
  position: relative;
}
.header {
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
  color: #000000;
}
.actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.button {
  width: 120px;
}
</style>
