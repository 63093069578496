<template>
  <div class="prev-messages-spinner">
    <Spinner />
  </div>
</template>

<script>
import Spinner from '@/components/Spinner'
export default {
  name: 'PrevMessagesSpinner',
  components: {
    Spinner
  }
}
</script>

<style lang="scss" scoped>
.prev-messages-spinner {
  width: 100%;
  height: 150px;
  position: relative;
  display: table;
}
</style>
