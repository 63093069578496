import { COMMON } from '@/store/types'

const state = () => ({
  windowInFocus: true,
  isSocketReconnecting: false
})

const getters = {
  [COMMON.GETTERS.WINDOW_IN_FOCUS]: (state) => state.windowInFocus,
  [COMMON.GETTERS.RECONNECTING]: (state) => state.isSocketReconnecting
}

const mutations = {
  [COMMON.MUTATIONS.SET_WINDOW_IN_FOCUS]: (state, status) => {
    state.windowInFocus = status
  },
  [COMMON.MUTATIONS.SET_RECONNECTING]: (state, status) => {
    state.isSocketReconnecting = status
  }
}

export default {
  state,
  getters,
  mutations
}
