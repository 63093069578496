<template>
  <UIModal @closeModal="$emit('closeModal')">
    <div class="modal-header">
      <h3>
        Extend chat time
      </h3>
      <div class="modal-body">
        Are you sure you want <br />
        to extend chat time?
      </div>
      <div class="modal-footer">
        <BaseButton hovered="false" class="base-button" @click="$emit('yes')">
          Yes
        </BaseButton>
        <BaseButton hovered="true" class="base-button" @click="$emit('no')">
          No
        </BaseButton>
      </div>
    </div>
  </UIModal>
</template>

<script>
import UIModal from '@/components/UI/UIModal'
import BaseButton from '@/components/BaseButton'

export default {
  components: { UIModal, BaseButton }
}
</script>

<style scoped lang="scss">
.base-button {
  width: 120px;
  margin: 0 5px;
}
.modal-header {
  font-size: 20px;
  font-weight: bold;
}
.modal-body {
  margin: 20px auto;
  font-size: 16px;
}
.modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
