import { CHAT_RESUME, MESSAGES } from '@/store/types'
import network from '@/lib/network'
import router from '@/router'
// import socket from '@/lib/network/socket'

export const clearResumeForm = {
  title: ``,
  text: ``,
  images: []
}

const state = () => ({
  formVisibility: false,
  currentChatResume: null,
  isSaving: false,
  modalType: `end`, // end | save
  form: { ...clearResumeForm }
})

const getters = {
  [CHAT_RESUME.GETTERS.FORM]: (state) => state.form,
  [CHAT_RESUME.GETTERS.MODAL_TYPE]: (state) => state.modalType,
  [CHAT_RESUME.GETTERS.FORM_VISIBILITY]: (state) => state.formVisibility,
  [CHAT_RESUME.GETTERS.SAVING]: (state) => state.isSaving,
  [CHAT_RESUME.GETTERS.RESUME_IS_CLEAR]: (state, getters) => {
    const chatResume = getters[MESSAGES.GETTERS.CHAT].resume
    return !chatResume || (!chatResume.title && !chatResume.text)
  }
}

const mutations = {
  [CHAT_RESUME.MUTATIONS.SET_MODAL_TYPE]: (state, type) => {
    state.modalType = type
  },
  [CHAT_RESUME.MUTATIONS.SET_FORM_VISIBILITY]: (state, visibility) => {
    state.formVisibility = visibility
  },
  [CHAT_RESUME.MUTATIONS.SET_RESUME_SAVING]: (state, savingStatus) => {
    state.isSaving = savingStatus
  },
  [CHAT_RESUME.MUTATIONS.SET_FORM]: (state, form) => {
    state.form = { ...form }
  },
  [CHAT_RESUME.MUTATIONS.SET_FORM_ITEM]: (state, { key, value }) => {
    state.form[key] = value
  },
  [CHAT_RESUME.MUTATIONS.CLEAR_FORM]: (state) => {
    state.error = { ...clearResumeForm }
  }
}

const actions = {
  async [CHAT_RESUME.ACTIONS.SAVE_RESUME]({ getters, commit, dispatch }) {
    commit(CHAT_RESUME.MUTATIONS.SET_RESUME_SAVING, true)
    try {
      const form = { ...getters[CHAT_RESUME.GETTERS.FORM] }

      // load files
      if (form.images.length) {
        const uploads = await Promise.all(
          form.images.map((file) => {
            if (!(file instanceof File)) {
              return Promise.resolve({ image_pk: file.id })
            }

            const formData = new FormData()
            formData.append(`image`, file)
            return network.uploadResumePhotos(formData)
          })
        )
        form.images_pks = uploads.map((upload) => upload.image_pk)
      }

      const isEndForm = getters[CHAT_RESUME.GETTERS.MODAL_TYPE] === `end`

      form.is_published = isEndForm
      await network.saveResume(router.currentRoute.params.pk, form)

      commit(CHAT_RESUME.MUTATIONS.SET_FORM_VISIBILITY, false)
      if (isEndForm) {
        await dispatch(MESSAGES.ACTIONS.CLOSE_CURRENT_CHAT)
        await dispatch(MESSAGES.ACTIONS.LOAD_CHAT_PAGE)
      }
    } catch (e) {
      console.log(e)
    } finally {
      commit(CHAT_RESUME.MUTATIONS.SET_RESUME_SAVING, false)
    }
  },
  [CHAT_RESUME.ACTIONS.CLOSE_BY_MISTAKE]({ dispatch, commit }) {
    dispatch(MESSAGES.ACTIONS.CLOSE_CURRENT_CHAT)
    commit(CHAT_RESUME.MUTATIONS.SET_FORM_VISIBILITY, false)
    dispatch(MESSAGES.ACTIONS.LOAD_CURRENT_CHAT, router.currentRoute.params.pk)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
