import { COMMON, MESSAGES } from '@/store/types'

export default (store) => {
  window.addEventListener(`focus`, () => {
    store.commit(COMMON.MUTATIONS.SET_WINDOW_IN_FOCUS, true)
    store.dispatch(MESSAGES.ACTIONS.READ_CURRENT_MESSAGES)
  })
  window.addEventListener(`blur`, () => {
    store.commit(COMMON.MUTATIONS.SET_WINDOW_IN_FOCUS, false)
  })
}
