<template>
  <div class="messages">
    <template v-for="message in messages">
      <Message
        v-if="!message.msg_type"
        :key="message.pk"
        :avatar="message.image"
        :name="message.name"
        :text="message.text"
        :time="message.creation_datetime"
        :read="message.is_read"
        :mine="message.is_mine"
        :images="message.images"
        class="help-message"
      />
      <SystemMessagesSwitcher v-else :key="message.pk" :message="message" />
    </template>
  </div>
</template>

<script>
import Message from '@/components/Chat/Message'
import { MESSAGES } from '@/store/types'
import { mapGetters } from 'vuex'
import SystemMessagesSwitcher from './system-messages/SystemMessagesSwitcher'
export default {
  name: `Messages`,
  components: {
    Message,
    SystemMessagesSwitcher
  },
  computed: {
    ...mapGetters({
      messages: MESSAGES.GETTERS.MESSAGES
    })
  }
}
</script>

<style scoped>
.messages {
  max-width: 890px;
  margin: 0 auto;
}
</style>
