<template>
  <div class="sidebar">
    <transition name="slide">
      <div v-if="isPanelOpen" class="sidebar-panel">
        <router-link to="/">
          <img src="@/assets/logo-white.svg" alt="logo-white" class="logo"/>
        </router-link>
        <router-link to="/" class="chat-requests">
          <span>Chat Requests</span>
          <NoticeCounter
            :class="{ 'middle-counter': requestCounter.length > 3 }"
          >
            {{ requestCounter }}
          </NoticeCounter>
        </router-link>
        <div></div>
        <router-link to="/questions" class="chat-requests">
          <span>Questions</span>
          <NoticeCounter
            :class="{ 'middle-counter': questionCounter.length > 3 }"
          >
            {{ questionCounter }}
          </NoticeCounter>
        </router-link>
      <!---->
        <p class="subtitle chats-list">
          My Chats
          <button style="border-radius: 30px;
                         padding: 8px 14px;
                         background-color: #00aa8d;
                         border-color: #0a7c68;
                         border: solid 2px;
                         color:white"
                  onmouseover="this.style.backgroundColor='white'; this.style.color='#00aa8d';"
                  onmouseout="this.style.backgroundColor='#00aa8d'; this.style.color='white';"
                  v-on:click="showAll">{{ showAllChats ? 'Show Active' : 'Show All' }}
          </button>

        </p>
        <div class="wrapper-box">
          <div class="box-persons">
            <router-link
              v-for="chat in chatList"
              :key="chat.pk"
              class="chat-link"
              :to="{ name: `Chat`, params: { pk: chat.pk } }"
            >
              <!-- Вреенно убрал chat.unread_amount из read-->
              <Person
                class="person"
                :name="chat.owner.name"
                :read="chat.unread_amount"
                :complited="chat.status"
              >
                <template v-slot:person>
                  <Avatar :image="chat.owner.image_url" :color="chat.color"/>
                </template>
              </Person>
            </router-link>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {mapMutations, mapGetters} from 'vuex'
import Person from '@/components/Person.vue'
import Avatar from '@/components/Avatar.vue'
import NoticeCounter from '@/components/NoticeCounter.vue'
import {SIDEBAR_OPEN, CHAT_LIST, CHAT_REQUESTS, QUESTIONS} from '@/store/types'
import store from "@/store";

export default {
  components: {Person, Avatar, NoticeCounter},
  data: () => ({
    showAllChats: false
  }),
  computed: {
    ...mapGetters({
      isPanelOpen: SIDEBAR_OPEN.GETTERS.GET_IS_NAV_OPEN,
      chatList: CHAT_LIST.GETTERS.GET_CHAT_LIST,
      requests: CHAT_REQUESTS.GETTERS.GET_CHAT_REQUESTS,
      questions: QUESTIONS.GETTERS.GET_QUESTIONS
    }),
    requestCounter() {
      return this.requests.length
    },
    questionCounter() {
      return this.questions.length
    },
  },
  mounted() {
    this.$store.dispatch(CHAT_LIST.ACTIONS.GET_CHAT_LIST)
    window.addEventListener('resize', this.checkWidth)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkWidth)
  },
  methods: {
    ...mapMutations({
      toggleNav: SIDEBAR_OPEN.MUTATIONS.TOGGLE_NAV
    }),
    showAll() {
      this.showAllChats = !this.showAllChats
      store.commit(CHAT_LIST.MUTATIONS.CHANGE_SHOW_ALL, 'qqq')
      this.$store.dispatch(CHAT_LIST.ACTIONS.GET_CHAT_LIST)
      /*
      window.removeEventListener('resize', this.checkWidth)
      this.$store.dispatch(CHAT_LIST.MUTATIONS.GET_CHAT_LIST)
      window.addEventListener('resize', this.checkWidth)*/
    },
    checkWidth() {
      // если понадобиться закрывать sidebar
      // if (document.body.clientWidth < 769 && this.isPanelOpen) this.toggleNav()
      if (document.body.clientWidth > 768 && !this.isPanelOpen) this.toggleNav()
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-link {
  display: flex;
  text-decoration: none;
  color: inherit;

  &.router-link-exact-active .person {
    background: #008b73;
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.2s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
  transition: all 150ms ease-in 0s;
}

.person {
  cursor: pointer;
}

.logo {
  display: flex;
  padding: 15px 20px 46px;
  box-sizing: border-box;
  min-height: 111px;
}

.sidebar-panel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  background-color: #00aa8d;
  width: 290px;
  position: relative;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100vh;
  overflow: hidden;

  .chat-requests {
    cursor: pointer;
    padding: 16px 20px;
    width: 100%;
    height: 46px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #009c82;
    text-decoration: none;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
  }

  .subtitle {
    padding: 0 20px;
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
  }

  .chats-list {
    margin-top: 30px;
    margin-bottom: 20px;
  }
}

.wrapper-box {
  position: relative;
  width: calc(100% + 20px);
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.box-persons {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

// MEDIA QUERIES //
@media screen and (max-width: 350px) {
  .sidebar-panel {
    width: 250px;
  }
}
</style>
