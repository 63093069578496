import Vue from 'vue'
import Vuex from 'vuex'
import chatListModule from './chatList'
import sidebarModule from './sidebar'
import messagesModule from './messages'
import chatRequestsModule from './chatRequests'
import profileModule from './profile'
import authModule from './auth'
import chatImagesSliderModule from '@/store/chatImagesSlider'
import commonModule from '@/store/common'
import notificationsModule from '@/store/notifications'
import chatResumeModule from '@/store/chatResume'
import videoChatsModule from '@/store/videoChats'
import petDialogModule from '@/store/pet-dialog'
import questionModule from '@/store/questions'
import chatCompleteModule from '@/store/chatComplete'



Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    petDialogModule,
    commonModule,
    sidebarModule,
    chatListModule,
    messagesModule,
    chatRequestsModule,
    authModule,
    profileModule,
    chatImagesSliderModule,
    notificationsModule,
    chatResumeModule,
    chatCompleteModule,
    videoChatsModule,
    questionModule
  }
})


export default store
