import {PROFILE} from '@/store/types'
import network from '@/lib/network'
import config from '@/config'
import socket from '@/lib/network/socket'

const state = () => ({
  info: {},
  error: '',
  success: ''
})

const getters = {
  [PROFILE.GETTERS.INFO]: (state) => state.info,
  [PROFILE.GETTERS.ERROR]: (state) => state.error,
  [PROFILE.GETTERS.SUCCESS]: (state) => state.success
}

const mutations = {
  [PROFILE.MUTATIONS.SET_INFO]: (state, userInfo) => (state.info = userInfo),
  [PROFILE.MUTATIONS.PASSWORD_ERROR]: (state, value) => {
    state.error = value
  },
  [PROFILE.MUTATIONS.PASSWORD_SUCCESS]: (state, value) => {
    state.success = value
  }
}

const actions = {
  async [PROFILE.ACTIONS.LOAD_INFO]({commit}) {
    try {
      let res = await network.loadProfileInfo()
      res.res.address = (res.res.address || '').toString()
      commit(PROFILE.MUTATIONS.SET_INFO, res.res)
    } catch (e) {
      console.log(e)
    }
  },
  async [PROFILE.ACTIONS.SAVE_INFO]({commit, getters}, data) {
    try {
      const {moderator} = await network.saveProfileInfo({
        ...getters[PROFILE.GETTERS.INFO],
        ...data
      })
      commit(PROFILE.MUTATIONS.SET_INFO, moderator)
    } catch (e) {
      console.log(e)
    }
  },
  async [PROFILE.ACTIONS.CHANGE_PASSWORD](
    {dispatch, commit},
    {password_old, password_new}
  ) {
    try {
      const {session_key} = await network.changePassword({
        password_old,
        password_new
      })
      localStorage.setItem(config.AUTH_KEY, session_key)
      socket.disconnect()
      socket.connect(session_key)
      await dispatch(PROFILE.ACTIONS.LOAD_INFO)
      commit(PROFILE.MUTATIONS.PASSWORD_SUCCESS, 'Access restore password')
    } catch (e) {
      if (e.response.status === 409) {
        commit(PROFILE.MUTATIONS.PASSWORD_ERROR, 'wrong password')
      }
    }
  },
  async [PROFILE.ACTIONS.UPLOAD_IMAGE]({dispatch}, data) {
    try {
      await network.chaneProfileImage(data)
      await dispatch(PROFILE.ACTIONS.LOAD_INFO)
    } catch (e) {
      console.log(e)
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
