<template>
  <div v-if="timeLeft" class="finished-at">
    <slot />
    {{ timeLeft }}
  </div>
</template>

<script>
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

export default {
  name: `FinishedAt`,
  props: {
    date: {
      type: String,
      default: ''
    }
  },
  watch: {
    date() {
      this.updateTime()
    }
  },
  data: () => ({
    tickTimeout: null,
    timeLeft: null // || ISO string
  }),
  mounted() {
    clearTimeout()
    this.tickTimeout = setInterval(this.updateTime, 1000)
    this.updateTime()
  },
  destroyed() {
    clearTimeout(this.tickTimeout)
    this.timeLeft = null
  },
  methods: {
    updateTime() {
      const date = dayjs(this.date)
      if (!!this.date && date.isAfter(dayjs())) {
        this.timeLeft = `${date.toNow(true)} left to chat`
      } else {
        this.timeLeft = null
      }
    }
  }
}
</script>

<style scoped>
.finished-at {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
</style>
