import router from '@/router'
let notificationsIsEnable = false
try {
  notificationsIsEnable = Notification.permission.toLowerCase() === `granted`
  if (!notificationsIsEnable) {
    Notification.requestPermission((result) => {
      if (result === `granted`) {
        notificationsIsEnable = true
      }
    })
  }
} catch (e) {
  console.error('Notifications is not supported')
}

export const browserNotification = ({ title, text, imageUrl, url }) => {
  if (notificationsIsEnable) {
    const notification = new Notification(title, {
      body: text,
      icon: imageUrl
    })

    if (url) {
      notification.onclick = () => {
        window.focus()
        router.push(url)
      }
    }
  }
}
