<template>
  <Dialog class="empty-passport-dialog" @closeModal="close">
    <div class="title">
      No pet
    </div>
    <div class="description">
      The user did not give data about the pet
    </div>
    <Button class="button" @click="close">Cancel</Button>
  </Dialog>
</template>

<script>
import UIModal from '@/components/UI/UIModal'
import BaseButton from '@/components/BaseButton'
import { PET_DIALOG } from '@/store/types'

export default {
  name: `EmptyPassportDialog`,
  components: {
    Dialog: UIModal,
    Button: BaseButton
  },
  methods: {
    close() {
      this.$store.commit(
        PET_DIALOG.MUTATIONS.SET_EMPTY_PASSPORT_DIALOG_VISIBILITY,
        false
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.empty-passport-dialog /deep/ .modal-container {
  width: 100%;
  max-width: 420px;
}
.title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
  color: #000000;
  margin-bottom: 20px;
}

.description {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.13;
  text-align: center;
  color: #000000;
  width: 200px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.button {
  width: 120px;
}
</style>
