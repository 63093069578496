import {AUTH, PROFILE} from '@/store/types'
import config from '@/config'
import router from '@/router'
import network from '@/lib/network'
import socket from '@/lib/network/socket'
import {videoChats} from '@/lib/video-chats/video-call'
import store from '@/store/index'

const state = () => ({
  auth: false,
  token: localStorage.getItem(config.AUTH_KEY),
  error: ''
})

const getters = {
  [AUTH.GETTERS.GET_AUTH]: (state) => !!state.token,
  [AUTH.GETTERS.GET_AUTH_ERROR]: (state) => state.error
}

const mutations = {
  [AUTH.MUTATIONS.SET_TOKEN]: (state, token) => {
    state.token = token
  },
  [AUTH.MUTATIONS.IS_AUTH]: (state, value) => {
    state.token = value
    if (state.token !== null) {
      state.auth = true
    }
  },
  [AUTH.MUTATIONS.AUTH_ERROR]: (state, value) => {
    state.error = value
  }
}

const actions = {
  [AUTH.ACTIONS.LOGOUT]: ({commit}) => {
    commit(AUTH.MUTATIONS.SET_TOKEN, null)
    socket.disconnect()
    localStorage.removeItem(config.AUTH_KEY)
    router.push({name: `Login`})
    videoChats.closeAll()
  },
  async [AUTH.ACTIONS.LOGIN]({commit, dispatch}, payload) {
    try {
      let res = await network.login(payload)
      console.log('Login res:', res)
      const session_key = res.res.access_token

      videoChats.closeAll()
      dispatch(PROFILE.ACTIONS.LOAD_INFO)
      commit(AUTH.MUTATIONS.IS_AUTH, true)
      localStorage.setItem(config.AUTH_KEY, session_key)
      socket.connect(session_key)
      router.push({name: 'ChatRequests'})
    } catch (e) {
      if (e.response.status === 400) {
        commit(AUTH.MUTATIONS.AUTH_ERROR, 'wrong login or password')
      }
    }
  },
  async [AUTH.ACTIONS.RESTORE_PASSWORD]({commit}, payload) {
    try {
      await network.restore(payload)
      router.push({path: '/'})
    } catch (e) {
      if (e.response.status === 409) {
        commit(AUTH.MUTATIONS.AUTH_ERROR, 'email not found')
      }
    }
  }
}

const onStorage = () => {
  const token = localStorage.getItem(config.AUTH_KEY)
  if (!token) {
    store.dispatch(AUTH.ACTIONS.LOGOUT)
  }
}

window.addEventListener(`storage`, onStorage, false)

export default {
  state,
  getters,
  mutations,
  actions
}

