<template>
  <div class="avatar">
    <div class="avatar-image" :style="avatarImage"/>
  </div>
</template>

<script>
import placeholder from '@/assets/user.svg'
import appConfig from '@/config'

export default {
  props: {
    image: {
      type: String,
      default: placeholder
    },
    color: {
      type: String,
      default: ''
    }
  },
  computed: {
    avatarImage() {
      if (!this.image || (this.image === placeholder)) {
        return `background-image: url('${placeholder}');`

      } else {
        console.log(`${window.localStorage.getItem(appConfig.AUTH_KEY)}`)
        // return `background-image: url('${appConfig.BACKEND_URL}${this.image}?token=${window.localStorage.getItem(appConfig.AUTH_KEY)}');`
        return `background-image: url('${this.image}?token=${window.localStorage.getItem(appConfig.AUTH_KEY)}');`

      }
      // const image = !this.image ? placeholder : this.image;
      //return `background-image: url('${appConfig.BACKEND_URL}${image}');`
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar {
  height: 100%;
  width: 100%;
  position: relative;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}

.avatar-image {
  border-radius: 50%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
