<template>
  <div class="modal-mask">
    <div class="modal-wrapper" @click.self="$emit('closeModal')">
      <div class="modal-container">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UIModal',
  mounted() {
    // закрывать окно при помощи кнопки 'esc'
    document.addEventListener('keyup', this.keyboardEscHandler)
  },
  destroyed() {
    // закрывать окно при помощи кнопки 'esc'
    document.removeEventListener('keyup', this.keyboardEscHandler)
  },
  methods: {
    keyboardEscHandler(e) {
      if (e.keyCode === 27) {
        this.$emit('closeModal')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  min-width: 320px;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
  overflow: hidden;
  overflow-y: auto;
  cursor: pointer;
}

.modal-wrapper {
  width: 100%;
  height: auto;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.modal-container {
  text-align: center;
  margin: 0px auto;
  padding: 30px;
  width: 420px;
  border-radius: 8px;
  box-shadow: 1px 2px 16px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  cursor: default;
}

@media screen and (max-width: 450px) {
  .modal-container {
    width: 280px;
    padding: 30px 20px;
  }
}
</style>
