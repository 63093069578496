<template>
  <div class="resume-images">
    <div v-if="maxFilesLength" class="error">
      The maximal amount of images is 10. Remove excess photos.
    </div>
    <transition name="fade">
      <div v-if="!maxFilesLength">
        <input
          id="uploader"
          ref="uploader"
          type="file"
          multiple
          accept="image/*"
          @change="imagesSelected"
        />
      </div>
    </transition>
    <transition name="fade">
      <div class="images">
        <div
          v-for="(image, index) in images"
          :key="index"
          class="image"
          :style="{ backgroundImage: `url('${image.url}')` }"
        >
          <img
            src="~@/assets/close.svg"
            class="close"
            @click="deleteImage(index)"
          />
        </div>
        <label v-if="!maxFilesLength" class="uploader-label" for="uploader">
          <img src="@/assets/photo-camera.svg" />
        </label>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'ResumeImages',
  props: {
    imagesUrls: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    files: [],
    images: []
  }),
  computed: {
    maxFilesLength() {
      return this.files.length > 10
    }
  },
  watch: {
    files(files) {
      this.$emit(`files-changed`, files)
    }
  },
  mounted() {
    this.files = [...this.imagesUrls, ...this.files]
    this.drawImages()
  },
  methods: {
    deleteImage(index) {
      this.files.splice(index, 1)
      this.images.splice(index, 1)
    },
    imagesSelected() {
      this.files = [...this.files, ...Array.from(this.$refs.uploader.files)]
      this.$refs.uploader.value = null
      this.drawImages()
    },
    drawImages() {
      let images = this.files
      if (!images.length) {
        this.images = []
        return
      }
      this.images = []

      images.forEach((image) => {
        if (!(image instanceof File)) {
          this.images.push(image)
          return
        }

        const reader = new FileReader()
        reader.onload = () => {
          this.images.push({
            url: String(reader.result)
          })
        }
        reader.readAsDataURL(image)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.resume-images {
  display: flex;
  flex-direction: column;
}
.uploader-label {
  width: 60px;
  height: 60px;
  border-radius: 7px;
  border: solid 1px #e3e3e3;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.error {
  background: #00aa8d;
  color: #fff;
  padding: 3px 7px;
  align-self: flex-start;
  border-radius: 10px;
  font-size: 10px;
}
label {
  margin-bottom: 10px;
}
.images {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
  min-height: 50px;
}
.image {
  width: 60px;
  height: 60px;
  margin-right: 10px;
  background-position: center;
  background-size: cover;
  border-radius: 5px;
  position: relative;
  margin-bottom: 10px;
}
.close {
  cursor: pointer;
  position: absolute;
  top: 2px;
  right: 2px;
  padding: 3px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}
#uploader {
  display: none;
}

@media screen and (max-width: 860px) {
  .images {
    max-height: 110px;
    overflow: auto;
  }
}
</style>
