<template>
  <div class="base-textarea">
    <label class="base-textarea_label">{{ label }}</label>
    <textarea
      ref="input"
      :value="value"
      :placeholder="placeholder"
      type="text"
      autocomplete="on"
      class="base-textarea_textarea"
      @input="$emit('change', $event.target.value)"
      @keyup="$emit('keyup', $event)"
      @keydown="$emit('keydown', $event)"
    />
  </div>
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    }
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    }
  }
}
</script>

<style lang="scss" scoped>
.base-textarea .base-textarea_label {
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  color: #000;
}

.base-textarea .base-textarea_textarea {
  width: 100%;
  height: 90px;
  color: #000000c4;
  font-size: 14px;
  background-color: #fff;
  padding: 15px;
  padding-bottom: 0;
  resize: none;
  border: none;
  outline: none;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  font-family: inherit;
  &::placeholder {
    font-size: 14px;
    font-weight: 300;
    line-height: 1;
    color: #9c9c9c;
  }
}
@media screen and (max-width: 520px) {
  .base-textarea .base-textarea_textarea {
    font-size: 16px;
  }
}
</style>
