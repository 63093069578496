<template>
  <LoginPage class="forgot-password-page">
    <Card class="help-card">
      <form
        id="login"
        action="#"
        method="post"
        class="login-card_form"
        @submit.prevent="checkForm"
      >
        <div class="login-card_info">
          <div class="login-card_body">
            <BaseInput
              v-model="commonField"
              placeholder="Enter your E-mail"
              type="text"
              label="E-mail or Phone"
              :error="forgotPasswordError"
              @focus="clearError()"
            />
          </div>
          <transition name="fade">
            <p v-if="forgotPasswordError" class="error">
              {{ forgotPasswordError }}
            </p>
            <p v-if="invalidField" class="error">Wrong Email or Phone</p>
          </transition>
          <div class="login-card_actions">
            <router-link to="/login" class="base-link">
              Login
            </router-link>
            <BaseButton
              type="submit"
              class="base-button"
              :disabled="invalidField"
            >
              Send password
            </BaseButton>
          </div>
        </div>
      </form>
    </Card>
  </LoginPage>
</template>

<script>
import { mapGetters } from 'vuex'
import LoginPage from '@/components/Login/LoginPage'
import BaseButton from '@/components/BaseButton'
import BaseInput from '@/components/BaseInput'
import Card from '@/components/Card'
import { AUTH } from '@/store/types'

export default {
  components: { LoginPage, BaseButton, BaseInput, Card },
  data() {
    return {
      commonField: '',
      email: '',
      phone: '',
      invalidField: false
    }
  },
  computed: {
    ...mapGetters({
      forgotPasswordError: AUTH.GETTERS.GET_AUTH_ERROR
    })
  },
  created() {
    this.$store.commit(AUTH.MUTATIONS.AUTH_ERROR, '')
  },
  methods: {
    isValid() {
      let isInvalid = true
      if (
        this.commonField
          .trim()
          .match(/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/)
      ) {
        this.phone = this.commonField
        return (isInvalid = false)
      }
      if (
        this.commonField
          .trim()
          .match(
            "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
          )
      ) {
        this.email = this.commonField
        return (isInvalid = false)
      }
      this.invalidField = isInvalid
      return isInvalid
    },
    async checkForm() {
      if (this.isValid()) return
      this.$store.dispatch(
        AUTH.ACTIONS.RESTORE_PASSWORD,
        !this.email
          ? { phone: this.phone.trim() }
          : { email: this.email.trim() }
      )
    },
    clearError() {
      this.invalidField = false
      this.email = ''
      this.phone = ''
      this.$store.commit(AUTH.MUTATIONS.AUTH_ERROR, '')
    }
  }
}
</script>

<style lang="scss" scoped>
.base-link {
  color: #00aa8d;
}

.login-card_form {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  background: rgba(0, 170, 141, 0.1);
}

.login-card_info {
  padding: 30px 20px;

  .title {
    font-size: 24px;
    color: #00aa8d;
    font-weight: 600;
    text-align: center;
    margin-top: 0;
  }

  .login-card_actions {
    text-align: center;

    .base-button {
      margin-top: 20px;
    }
  }
}

.error {
  color: #b83625;
  text-align: center;
  margin-bottom: 10px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

@media (max-width: 480px) and (max-height: 360px) {
  .forgot-password-page {
    display: block;
  }
  .help-card {
    margin: 0 auto;
  }
}
@media (min-width: 480px) and (max-height: 380px) {
  .forgot-password-page {
    display: block;
  }
  .help-card {
    margin: 0 auto;
  }
}
@media screen and (max-width: 480px) {
  .login-card_info {
    padding: 20px;
  }
}
</style>
