<template>
  <span
    class="tooltip-container"
    @click.stop.prevent="showModal()"
    @mousemove="showModal()"
    @mouseleave="hideModalWithDelay()"
  >
    <transition name="fade">
      <div
        v-if="isShow"
        key="content"
        class="tooltip"
        :class="`position-${position}`"
      >
        <slot name="tooltip-content" />
      </div>
    </transition>
    <span>
      <slot />
    </span>
  </span>
</template>

<script>
export default {
  props: ['position'],
  data: () => ({
    isShow: false,
    timeout: null
  }),
  mounted() {
    document.addEventListener(`click`, this.clickHandler)
  },
  destroy() {
    document.removeEventListener(`click`, this.clickHandler)
  },
  methods: {
    clickHandler() {
      this.isShow = false
    },
    showModal() {
      clearTimeout(this.timeout)
      this.isShow = true
    },
    hideModalWithDelay() {
      this.timeout = setTimeout(() => {
        this.isShow = false
      }, 1200)
    }
  }
}
</script>

<style lang="scss" scoped>
.tooltip-container {
  position: relative;
}

.tooltip {
  width: max-content;
  position: absolute;
  transform: translateX(100%);
  top: 30px;
  background: #ffffff;
  border-radius: 8px;
  z-index: 1;
  box-shadow: 1px 2px 16px 0 rgba(0, 0, 0, 0.1);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.position-left {
  position: absolute;
  left: -60px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}
</style>
